import { Component, ViewChild } from '@angular/core';
import { OrganisationUnitService } from '../../../services/organisation-unit.service';
import { tap, concatMap } from 'rxjs';
import { OrganisationUnitModel } from '../../../models/organisation-unit/organisation-unit.model';
import { OrganisationUnitCreationModel } from '../../../models/organisation-unit/organisation-unit-creation.model';
import { OrganisationUnitUpdateModel } from '../../../models/organisation-unit/organisation-unit-update.model';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from '../../../services/loading.service';
import { DxSelectBoxTypes } from 'devextreme-angular/ui/select-box';
import { DxFormComponent } from 'devextreme-angular/ui/form';
import { FileUtilService } from '../../../services/file-util.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import validationEngine from 'devextreme/ui/validation_engine';
import { DxDropDownBoxComponent } from 'devextreme-angular';

@Component({
  selector: 'app-organization-unit-form',
  templateUrl: './organization-unit-form.component.html',
  styleUrls: ['./organization-unit-form.component.scss'],
})
export class OrganizationUnitFormComponent {
  @ViewChild('form', { static: true }) form!: DxFormComponent;
  @ViewChild('bottomForm', { static: true }) bottomForm!: DxFormComponent;
  @ViewChild(DxDropDownBoxComponent, { static: false }) parentUnitDropDown!: DxDropDownBoxComponent;

  editingOrganisationUnitId: string | null;
  editingOrganisationUnit: OrganisationUnitModel | null = null;
  organisationUnits: OrganisationUnitModel[] = [];
  organisationUnitForm:
    | OrganisationUnitCreationModel
    | OrganisationUnitUpdateModel = {
    name: '',
    description: '',
    parentId: '',
    reportFooter: '',
    type: null,
    image: null,
  };
  imageBlob: Blob | null = null;

  typeOptions!: DxSelectBoxTypes.Properties;

  constructor(
    private organisationUnitService: OrganisationUnitService,
    private route: ActivatedRoute,
    private router: Router,
    private loadingService: LoadingService,
    private fileUtilService: FileUtilService,
    private toastrService: ToastrService,
    private translateService: TranslateService
  ) {
    const parentId: string | null = this.route.snapshot.queryParams['parent'];
    this.editingOrganisationUnitId = this.route.snapshot.params['id'];

    if (this.editingOrganisationUnitId) {
      const result = this.organisationUnitService
        .getAllOrganisationUnits()
        .pipe(
          tap(
            (organisationUnits) => {
              this.organisationUnits = organisationUnits;
            }
          ),
          concatMap(() =>
            this.organisationUnitService.getOrganisationUnit(
              this.editingOrganisationUnitId!
            )
          )
        );
      result.subscribe((organisationUnit) => {
        this.editingOrganisationUnit = organisationUnit;

        if (this.editingOrganisationUnit.type == 1) {
          this.organisationUnits = this.organisationUnits.filter((ou: any) => ou.type == 1);
        }
        this.setDropdownOptions();
        this.patchForm(organisationUnit);
      });
    } else {
      this.organisationUnitService
        .getAllOrganisationUnits()
        .pipe(
          tap(
            (organisationUnits) => (this.organisationUnits = organisationUnits)
          )
        )
        .subscribe(() => {
          this.organisationUnitForm.parentId = parentId!;
          this.setDropdownOptions();
        });
    }
  }

  navigateBack(): void {
    this.router.navigate(['/organisation-unit']);
  }

  onFormSubmit(): void {
    const isFormValid = this.form.instance.validate().isValid;
    const isCustomFieldsValid = validationEngine.validateGroup('customFields').isValid;
    
    if (isFormValid) {
      if (this.editingOrganisationUnitId) {
        const organizationUnit = {
          ...this.organisationUnitForm,
          image: this.getImage(),
        };

        const result = this.organisationUnitService.updateOrganisationUnit(
          this.editingOrganisationUnitId!,
          organizationUnit
        );
        this.loadingService.load(result).subscribe({
          next: (updatedOrganisationUnit) => {
            this.toastrService.success(
              updatedOrganisationUnit.name +
                this.translateService.instant('COMMON.UPDATED-SUCCESSFULLY')
            );
            this.router.navigateByUrl('organisation-unit');
          },
          error: () =>
            this.toastrService.error(
              this.translateService.instant('COMMON.UNKNOWN-ERROR')
            ),
        });
      } else {
        const organizationUnit = {
          ...this.organisationUnitForm,
          image: this.getImage(),
        } as OrganisationUnitCreationModel;

        const result =
          this.organisationUnitService.addOrganisationUnit(organizationUnit);
        this.loadingService.load(result).subscribe({
          next: (createdOrganisationUnit) => {
            this.toastrService.success(
              createdOrganisationUnit.name +
                this.translateService.instant('COMMON.CREATED-SUCCESSFULLY')
            );
            this.router.navigateByUrl('organisation-unit');
          },
          error: () =>
            this.toastrService.error(
              this.translateService.instant('COMMON.UNKNOWN-ERROR')
            ),
        });
      }
    }
  }

  setDropdownOptions() {
    const defaultSelectOptions = {
      showClearButton: true,
      stylingMode: 'outlined',
      displayExpr: 'name',
      valueExpr: 'id',
      disabled: this.editingOrganisationUnitId ? true : false
    } as DxSelectBoxTypes.Properties;

    const parentOrganizationUnitType = this.organisationUnits.find(ou => ou.id === this.organisationUnitForm.parentId)?.type;
    const types = [{
      id: 2,
      name: this.translateService.instant('COMMON.FIELDS.DEPARTMENT'),
    }];

    if (parentOrganizationUnitType == 1 || this.editingOrganisationUnitId) {
      types.push({
        id: 1,
        name: this.translateService.instant('COMMON.FIELDS.LOCATION'),
      });
    }

    this.typeOptions = {
      ...defaultSelectOptions,
      dataSource: types,
    };
  }

  onOrganisationUnitSelected(e: any) {
    if (e.node.key) {
      this.organisationUnitForm.parentId = e.node.key;
      this.setDropdownOptions();
    }
  }

  onImageUploaded(files: any) {
    const file = files[0];
    this.imageBlob = new Blob([file], { type: file.type });
    this.organisationUnitForm.image = URL.createObjectURL(this.imageBlob);
  }

  getImage(): File | null {
    if (this.imageBlob) {
      return new File([this.imageBlob!], 'defaultname');
    } else return null;
  }

  private patchForm(organisationUnit: OrganisationUnitModel) {
    this.organisationUnitForm.name = organisationUnit.name;
    this.organisationUnitForm.description = organisationUnit.description;
    this.organisationUnitForm.parentId = organisationUnit.parentId!;
    this.organisationUnitForm.reportFooter = organisationUnit.reportFooter;
    this.organisationUnitForm.type = organisationUnit.type!;

    if (organisationUnit.imageUrl) {
      this.loadingService
        .load(this.fileUtilService.imageUrlToBlob(organisationUnit.imageUrl!))
        .subscribe((blob) => {
          this.imageBlob = blob;
          this.organisationUnitForm.image = URL.createObjectURL(blob);
        });
    }
  }

  onBackClick() {
    this.router.navigate(['/employee']);
  }

  onParentUnitsSelected(e: any) {
    if (e.node.key) {
      this.organisationUnitForm.parentId = e.node.key;
      this.setDropdownOptions();
    }
  }
}
