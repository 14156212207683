import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TrainingTemplateService } from '../../../services/training-template.service';
import { OrganisationUnitService } from '../../../services/organisation-unit.service';
import {
  debounceTime,
  distinctUntilChanged,
  forkJoin,
  Subject,
  Subscription,
} from 'rxjs';
import { OrganisationUnitModel } from '../../../models/organisation-unit/organisation-unit.model';
import { shareReplay, switchMap } from 'rxjs/operators';
import { OrganisationUnitTypeModel } from '../../../models/organisation-unit/organisation-unit-type.model';
import { TrainingGroupModel } from '../../../models/training-group.model';
import { PersonService } from '../../../services/person.service';
import { TrainingGroupService } from '../../../services/training-group.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoadingService } from '../../../services/loading.service';
import { DxSelectBoxTypes } from 'devextreme-angular/ui/select-box';
import { DxFormComponent } from 'devextreme-angular/ui/form';
import { FileUtilService } from '../../../services/file-util.service';
import { TrainingTypeModel } from '../../../models/training/training-type.model';
import { TrainingTemplateSummaryModel } from '../../../models/training-template/training-template-summary.model';
import { TrainingService } from '../../../services/training.service';
import { TrainingCreationAndUpdateModel } from '../../../models/training/training-creation-and-update.model';
import { TrainingLocationTypeModel } from '../../../models/training/training-location-type.model';
import { GoogleMapsService } from '../../../services/google-maps.service';
import { TrainingDateSelectionComponent } from '../../../components/training-date-selection/training-date-selection.component';
import { TrainingFullModel } from '../../../models/training/training-full.model';
import { TimeService } from '../../../services/time.service';
import { TrainingHelperService } from '../../../services/training-helper.service';
import {
  PrimaryButtonType,
  SecondaryButtonType,
} from '../../../models/button-type.model';
import { DxDropDownButtonTypes } from 'devextreme-angular/ui/drop-down-button';
import { TranslateService } from '@ngx-translate/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { environment } from '../../../../environments/environment';
import { EventMemberStatusModel } from '../../../models/event-member-status.model';
import { EventMemberModel } from '../../../models/event-member.model';
import { ConfirmationDialogData } from 'src/app/components/shared/confirmation-dialog/confirmation-dialog.component';
import { IntegrationsService } from '../../../services/integration/integrations.service';
import { IntegrationModel } from '../../../models/integration/integration.model';
import { IntegrationTypeModel } from '../../../models/integration/integration-type.model';
import { CompletedEventService } from '../../../services/completed-event.service';
import { TrainingCategoryModel } from '../../../models/training-category.model';
import { TrainingCategoryService } from '../../../services/training-category.service';
import { UserService } from '../../../services/user.service';
import validationEngine from 'devextreme/ui/validation_engine';
import { ExternalContactService } from 'src/app/services/external-contacts.service';
import { DxDropDownBoxComponent } from 'devextreme-angular';
import { TrainingEmployeePopupComponent } from '../../../components/training-employee-popup/training-employee-popup.component'

interface SelectOption {
  id: string;
  name: string;
  selected?: boolean;
}

@Component({
  selector: 'app-training-form',
  templateUrl: './training-form.component.html',
  styleUrls: ['./training-form.component.scss'],
})
export class TrainingFormComponent implements OnInit {
  @ViewChild('descriptionForm', { static: true })
  descriptionForm!: DxFormComponent;
  @ViewChild('settingsForm', { static: true }) settingsForm!: DxFormComponent;
  @ViewChild('locationForm', { static: true }) locationForm!: DxFormComponent;
  @ViewChild('trainingDateSelection')
  trainingDateSelectionComponent!: TrainingDateSelectionComponent;
  @ViewChild(DxDropDownBoxComponent, { static: false }) templateDropDown!: DxDropDownBoxComponent;
  private googleAutocomplete!: ElementRef<HTMLElement>;
  @ViewChild('googleAutocomplete') set googleAutocompleteContent(content: ElementRef<HTMLElement>) {
    if (content) {
      this.googleAutocomplete = content;
    }
  }
  private timePicker!: ElementRef<HTMLElement>;
  @ViewChild('timePicker') set timePickerContent(content: ElementRef<HTMLElement>) {
    if (content) {
      this.timePicker = content;
    }
  }
  @ViewChild('trainingEmployeeModal') trainingEmployeeModal!: TrainingEmployeePopupComponent;

  private googlePlaceinputChanged = new Subject<string>();
  googleAutocompleteResults$ = this.googlePlaceinputChanged.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    switchMap((searchTerm) => this.mapsService.getAutocomplete(searchTerm))
  );

  private subs = new Subscription();

  training: TrainingFullModel | null = null;
  isWbdConfigured: boolean = false;
  isViewMode: boolean = false;
  isEmployeePopupVisible: boolean = false;
  employeePopupTab: number = 0;
  openMembers: EventMemberModel[] = [];
  acceptedMembers: EventMemberModel[] = [];
  declinedMembers: EventMemberModel[] = [];
  requestedMembers: EventMemberModel[] = [];
  googlePlaces: any[] = [];
  googleSearchText: string = '';
  TrainingLocationType = TrainingLocationTypeModel;
  TrainingTypeModel = TrainingTypeModel;
  editingTrainingId!: string;
  organizationUnits!: OrganisationUnitModel[];
  trainingGroups!: TrainingGroupModel[];
  trainingCategories!: TrainingCategoryModel[];
  templates!: TrainingTemplateSummaryModel[];
  locations!: SelectOption[];
  departments!: SelectOption[];
  trainers!: SelectOption[];
  trainingTypeOptions!: DxSelectBoxTypes.Properties;
  learningTypeOptions!: DxSelectBoxTypes.Properties;
  learningContentOptions!: DxSelectBoxTypes.Properties;
  trainingLocationTypeOptions!: DxSelectBoxTypes.Properties;
  primaryButton: PrimaryButtonType | null = null;
  buttonTypes: SecondaryButtonType[] | null = null;
  isDescriptionExpanded: boolean = false;
  integrations: Array<any> = [];

  toggleDescriptionExpanded() {
    this.isDescriptionExpanded = !this.isDescriptionExpanded;
  }

  menuActions: { value: SecondaryButtonType; name: string }[] = [
    {
      value: SecondaryButtonType.CopyLink,
      name: this.translateService.instant('TRAINING.FORM.COPY-INVITATION-LINK'),
    },
    {
      value: SecondaryButtonType.DownloadMemberList,
      name: this.translateService.instant('TRAINING.FORM.ATTENDANCE-LIST'),
    },
    {
      value: SecondaryButtonType.DownloadCompletedEventMemberList,
      name: this.translateService.instant('TRAINING.FORM.VIEW-ATTENDEES-LIST'),
    },
    {
      value: SecondaryButtonType.DownloadIcs,
      name: this.translateService.instant('TRAINING.FORM.EXPORT-CALENDAR'),
    },
    {
      value: SecondaryButtonType.SendInfo,
      name: this.translateService.instant('TRAINING.FORM.NOTE-PARTICIPANTS'),
    },
    {
      value: SecondaryButtonType.Edit,
      name: this.translateService.instant('TRAINING.FORM.EDIT-TRAINING'),
    },
    {
      value: SecondaryButtonType.Cancel,
      name: this.translateService.instant('TRAINING.FORM.CANCEL-TRAINING'),
    },
    {
      value: SecondaryButtonType.Delete,
      name: this.translateService.instant('TRAINING.FORM.DELETE-TRAINING'),
    },
  ];

  trainingForm: TrainingCreationAndUpdateModel = {
    name: null,
    description: null,
    descriptionExtended: null,
    trainerIds: [],
    trainingType: null,
    iddTimeMinutes: null,
    iddHours: 0,
    iddMinutes: 0,
    sendCertificateLink: true,
    sendFeedbackLink: true,
    organisationUnitLocationIds: [],
    trainingGroupIds: [],
    trainingCategoryIds: [],
    maximumMembers: null,
    trainingLocation: {
      locationType: null,
      location: null,
      suffix: null,
      placeId: null,
      latitude: null,
      longitude: null,
    },
    templateId: null,
    emailBody: null,
    imageBase64: null,
    dates: [],
    lernart: null,
    lerninhalt: null,
  };

  selectedOrganizationUnitsIds: Array<any> = [];
  modalConfirmationOptions: ConfirmationDialogData | null = null;
  showDeleteTrainingModal = false;
  showCancelTrainingModal = false;

  isTrainingTimeValid: boolean = true;
  isTrainingLocationValid: boolean = true;
  trainingTimeAdapter = {
    getValue: () => {
      return this.trainingForm.iddHours! * 60 + this.trainingForm.iddMinutes!;
    },
  };
  trainingLocationAdapter = {
    getValue: () => {
      return this.googleSearchText;
    },
  };

  get locationValidationStatus() {
    return !this.isTrainingLocationValid ? 'invalid' : 'valid';
  }

  get getTrainingLocationLabel() {
    return this.trainingForm.trainingLocation.locationType == 1
      ? this.translateService.instant('TRAINING.FORM.PLATFORM')
      : this.translateService.instant('COMMON.FIELDS.TRAINING-LOCATION');
  }

  get getTrainingLocationDetailsLabel() {
    return this.trainingForm.trainingLocation.locationType == 1
      ? this.translateService.instant('TRAINING.FORM.LINK-OR-MEETING')
      : this.translateService.instant('COMMON.FIELDS.LOCATION-DETAILS');
  }

  get primaryButtonText() {
    switch (this.primaryButton) {
      case PrimaryButtonType.AddAttendees:
        return this.translateService.instant('TRAINING.FORM.ADD-ATTENDEES');
      case PrimaryButtonType.CompleteTraining:
        return this.translateService.instant('TRAINING.FORM.CLOSE');
      case PrimaryButtonType.ShowFeedback:
        return this.translateService.instant('TRAINING.FORM.SHOW-FEEDBACK');
      case PrimaryButtonType.DownloadCompletedEventMemberList:
        return this.translateService.instant(
          'TRAINING.FORM.VIEW-ATTENDEES-LIST'
        );
      default:
        return this.translateService.instant('TRAINING.FORM.ADD-ATTENDEES');
    }
  }

  constructor(
    private organisationUnitService: OrganisationUnitService,
    private personService: PersonService,
    private externalContactService: ExternalContactService,
    private trainingGroupService: TrainingGroupService,
    private trainingCategoryService: TrainingCategoryService,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private router: Router,
    private loadingService: LoadingService,
    private fileUtilService: FileUtilService,
    private trainingTemplateService: TrainingTemplateService,
    private trainingService: TrainingService,
    readonly trainingHelperService: TrainingHelperService,
    private mapsService: GoogleMapsService,
    readonly translateService: TranslateService,
    private clipboard: Clipboard,
    private integrationsService: IntegrationsService,
    private completedEventService: CompletedEventService,
    private userService: UserService
  ) {
    this.autocompleteSearchFilter = this.autocompleteSearchFilter.bind(this);

    this.editingTrainingId = this.route.snapshot.params['id'];
    const path = this.route.snapshot.routeConfig!.path!;
    if (!path.includes('training/add') && !path.includes('training/edit')) {
      this.isViewMode = true;
    }

    const formData = this.loadingService
      .load(
        forkJoin({
          trainingGroups: this.trainingGroupService.getAllTrainingGroups(),
          trainingCategories:
            this.trainingCategoryService.getAllTrainingCategories(),
          organizationUnits:
            this.organisationUnitService.getAllOrganisationUnits(),
          trainers: this.personService.getAllPersons(),
          externalContacts: this.externalContactService.getAllContacts(),
          templates: this.trainingTemplateService.getAllTrainingTemplates(),
          integrations: this.integrationsService.getIntegrations(),
          isWbdConfigured: this.userService.getWbdConfigured(),
        })
      )
      .pipe(shareReplay(1));

    formData.subscribe(
      ({
        trainingGroups,
        trainingCategories,
        organizationUnits,
        trainers,
        externalContacts,
        templates,
        integrations,
        isWbdConfigured,
      }) => {
        this.trainingGroups = trainingGroups;
        this.trainingCategories = trainingCategories;
        this.templates = templates;
        this.organizationUnits = organizationUnits;
        this.isWbdConfigured = isWbdConfigured;
        this.integrations = integrations;

        this.locations = organizationUnits
          .filter(
            (organizationUnit) =>
              organizationUnit.type == OrganisationUnitTypeModel.Location
          )
          .map((location) => ({ id: location.id, name: location.name }));

        this.departments = organizationUnits
          .filter(
            (organizationUnit) =>
              organizationUnit.type == OrganisationUnitTypeModel.Department
          )
          .map((department) => ({ id: department.id, name: department.name }));

        this.trainers = trainers.map((trainer) => ({
          id: trainer.id,
          name: `${trainer.firstName} ${trainer.lastName}`,
        }));

        externalContacts.forEach(contact => {
          this.trainers.push({
            id: contact.id,
            name: `${contact.firstName} ${contact.lastName}`,
          })
        });

        this.setDropdownOptions(integrations);

        if (this.editingTrainingId) {
          const trainingAndMembers = this.loadingService
            .load(
              forkJoin({
                training: trainingService.getTraining(this.editingTrainingId),
                members: trainingService.getEventMembers(
                  this.editingTrainingId
                ),
              })
            )
            .pipe(shareReplay(1));

          trainingAndMembers.subscribe(({ training, members }) => {
            this.training = training;
            this.patchForm(training);

            this.openMembers = members.filter(
              (m) => m.status == EventMemberStatusModel.Open
            );
            this.acceptedMembers = members.filter(
              (m) => m.status == EventMemberStatusModel.Accepted
            );
            this.declinedMembers = members.filter(
              (m) => m.status == EventMemberStatusModel.Declined
            );
            this.requestedMembers = members.filter(
              (m) => m.status == EventMemberStatusModel.Requested
            );

            this.primaryButton =
              this.trainingHelperService.getPrimaryButtonType(training);
            this.buttonTypes =
              this.trainingHelperService.getSecondaryButtonTypes(
                training,
                members
              );

            this.menuActions = this.menuActions.filter((item) =>
              this.buttonTypes!.includes(item.value)
            );
          });
        }
      }
    );
  }

  onLoadMembers() {
    const members = this.loadingService
      .load(this.trainingService.getEventMembers(this.editingTrainingId))
      .pipe(shareReplay(1));

    members.subscribe((members) => {
      this.openMembers = members.filter(
        (m) => m.status == EventMemberStatusModel.Open
      );
      this.acceptedMembers = members.filter(
        (m) => m.status == EventMemberStatusModel.Accepted
      );
      this.declinedMembers = members.filter(
        (m) => m.status == EventMemberStatusModel.Declined
      );
      this.requestedMembers = members.filter(
        (m) => m.status == EventMemberStatusModel.Requested
      );

      setTimeout(() => {
        this.trainingEmployeeModal.reloadPersonsCount();
      });
    });
  }

  ngOnInit(): void {
    this.googleAutocompleteResults$.subscribe((results) => {
      this.googlePlaces = results;
    });
  }

  navigateBack(): void {
    if (this.isViewMode || !this.editingTrainingId) {
      this.router.navigate(['/training']);
    } else {
      this.router.navigate(['/training/' + this.editingTrainingId]);
    }
  }

  navigateViewMode(id: string): void {
    this.router.navigate(['/training/' + id]);
  }

  onFormSubmit(): void {
    let isValidationFailed = false;
    const isDescriptionFormValid = this.descriptionForm.instance.validate();
    const isSettingsFormValid = this.settingsForm.instance.validate();
    const isLocationFormValid = this.locationForm.instance.validate();
    this.isTrainingLocationValid = true;

    if (!isDescriptionFormValid.isValid && !isValidationFailed) {
      // @ts-ignore
      isDescriptionFormValid.brokenRules[0].validator.focus();
      isValidationFailed = true;
    }

    if (!isSettingsFormValid.isValid && !isValidationFailed) {
      // @ts-ignore
      isSettingsFormValid.brokenRules[0].validator.focus();
      isValidationFailed = true;
    }

    if (!isLocationFormValid.isValid && !isValidationFailed) {
      // @ts-ignore
      isLocationFormValid.brokenRules[0].validator.focus();
      isValidationFailed = true;
    }

    if (!this.trainingForm.trainingLocation.latitude || !this.trainingForm.trainingLocation.longitude) {
      this.trainingForm.trainingLocation.location = this.googleSearchText;
    }

    const isTrainingTimeValid =
      this.trainingForm.trainingType !== 0
        ? {isValid: true}
        : validationEngine.validateGroup('trainingTimeGroup');
    this.isTrainingTimeValid = isTrainingTimeValid.isValid!;

    if (!isTrainingTimeValid.isValid && !isValidationFailed) {
      this.timePicker.nativeElement.scrollIntoView();
      isValidationFailed = true;
    }

    if (this.trainingForm.trainingLocation.locationType == 0) {
      const locationValidationResult = validationEngine.validateGroup('trainingLocationGroup');
      this.isTrainingLocationValid = locationValidationResult.isValid!;

      if (!this.isTrainingLocationValid && !isValidationFailed) {
        this.googleAutocomplete.nativeElement.scrollIntoView();
      }
    }

    this.trainingForm.organisationUnitLocationIds = [];

    if (!this.selectedOrganizationUnitsIds.length) {
      this.organizationUnits.forEach(unit => {
        this.trainingForm.organisationUnitLocationIds.push(unit.id);
      });
    } else {
      console.log("selected: ", this.selectedOrganizationUnitsIds);
      this.selectedOrganizationUnitsIds.forEach(id => {
        this.trainingForm.organisationUnitLocationIds.push(id);
      });
    }

    if (
      isDescriptionFormValid.isValid &&
      isSettingsFormValid.isValid &&
      isLocationFormValid.isValid &&
      isTrainingTimeValid.isValid &&
      this.isTrainingLocationValid &&
      this.trainingDateSelectionComponent.isValid()
    ) {
      if (this.editingTrainingId) {
        const training = {
          ...this.trainingForm,
          dates: this.trainingDateSelectionComponent.getDates(),
          imageBase64: this.trainingForm.imageBase64
            ? this.trainingForm.imageBase64!.split(',')[1]
            : null,
        };

        if (this.trainingForm.trainingType === 0) {
          training.iddTimeMinutes =
            this.trainingForm.iddHours! * 60 + this.trainingForm.iddMinutes!;
        } else {
          training.iddTimeMinutes = 0;
          training.lernart = null;
          training.lerninhalt = null;
        }

        const result = this.trainingService.updateTraining(
          this.editingTrainingId,
          training
        );

        this.loadingService.load(result).subscribe({
          next: () => {
            this.toastrService.success('Training successfully updated!');
            this.navigateViewMode(this.editingTrainingId);
          },
          error: () => this.toastrService.error('Training update error!'),
        });
      } else {
        const training: TrainingCreationAndUpdateModel = {
          ...this.trainingForm,
          dates: this.trainingDateSelectionComponent.getDates(),
          imageBase64: this.trainingForm.imageBase64
            ? this.trainingForm.imageBase64!.split(',')[1]
            : null,
        };

        if (!training.imageBase64) {
          delete training.imageBase64;
        }

        if (this.trainingForm.trainingType === 0) {
          training.iddTimeMinutes =
            this.trainingForm.iddHours! * 60 + this.trainingForm.iddMinutes!;
        } else {
          training.iddTimeMinutes = 0;
          training.lernart = null;
          training.lerninhalt = null;
        }

        const result = this.trainingService.addTraining(training);
        this.loadingService.load(result).subscribe({
          next: (training: TrainingFullModel) => {
            this.toastrService.success('Training successfully created!');
            this.navigateViewMode(training.training.id);
          },
          error: () => this.toastrService.error('Training creation error!'),
        });
      }
    }
  }

  setDropdownOptions(integrations: IntegrationModel[]) {
    const defaultSelectOptions = {
      stylingMode: 'outlined',
      displayExpr: 'name',
      valueExpr: 'id',
    } as DxSelectBoxTypes.Properties;

    this.trainingTypeOptions = {
      ...defaultSelectOptions,
      showClearButton: true,
      dataSource: [
        {
          id: 0,
          name: this.translateService.instant(
            'COMMON.FIELDS.TRAINING-TYPES.IDD'
          ),
        },
        {
          id: 1,
          name: this.translateService.instant(
            'COMMON.FIELDS.TRAINING-TYPES.COMPLIANCE'
          ),
        },
        {
          id: 2,
          name: this.translateService.instant(
            'COMMON.FIELDS.TRAINING-TYPES.GENERAL'
          ),
        },
      ],
      placeholder: '',
    };
    this.learningTypeOptions = {
      ...defaultSelectOptions,
      showClearButton: true,
      dataSource: [
        {
          id: 1,
          name: this.translateService.instant(
            'COMMON.FIELDS.LEARNING-TYPES.FACE-TO-FACE'
          ),
        },
        {
          id: 2,
          name: this.translateService.instant(
            'COMMON.FIELDS.LEARNING-TYPES.INDIVIDUAL-TRAINING'
          ),
        },
        {
          id: 3,
          name: this.translateService.instant(
            'COMMON.FIELDS.LEARNING-TYPES.BLENDED-LEARNING'
          ),
        },
        {
          id: 4,
          name: this.translateService.instant(
            'COMMON.FIELDS.LEARNING-TYPES.CONTROLLED-ELEARNING'
          ),
        },
        {
          id: 5,
          name: this.translateService.instant(
            'COMMON.FIELDS.LEARNING-TYPES.SELF-DIRECTED-ELEARNING'
          ),
        },
        {
          id: 6,
          name: this.translateService.instant(
            'COMMON.FIELDS.LEARNING-TYPES.LEARNING-AT-WORK'
          ),
        },
      ],
      placeholder: '',
    };
    this.learningContentOptions = {
      ...defaultSelectOptions,
      showClearButton: true,
      dataSource: [
        {
          id: 1,
          name: this.translateService.instant(
            'COMMON.FIELDS.LEARING-CONTENT-TYPES.PENSION-INSURANCE'
          ),
        },
        {
          id: 2,
          name: this.translateService.instant(
            'COMMON.FIELDS.LEARING-CONTENT-TYPES.NURSING-INSURANCE'
          ),
        },
        {
          id: 3,
          name: this.translateService.instant(
            'COMMON.FIELDS.LEARING-CONTENT-TYPES.DAMAGE-INSURANCE'
          ),
        },
        {
          id: 4,
          name: this.translateService.instant(
            'COMMON.FIELDS.LEARING-CONTENT-TYPES.CUSTOMER-PENSION-PROVISION'
          ),
        },
        {
          id: 5,
          name: this.translateService.instant(
            'COMMON.FIELDS.LEARING-CONTENT-TYPES.CUSTOMER-PROPERTY'
          ),
        },
        {
          id: 6,
          name: this.translateService.instant(
            'COMMON.FIELDS.LEARING-CONTENT-TYPES.CROSS-DIVISIONAL'
          ),
        },
        {
          id: 7,
          name: this.translateService.instant(
            'COMMON.FIELDS.LEARING-CONTENT-TYPES.ADVISORY-COMPETENCE'
          ),
        },
      ],
      placeholder: '',
    };

    this.setLocationTypeOptions(true);
  }

  setLocationTypeOptions(isIntegrationsAvailable: boolean) {
    const defaultSelectOptions = {
      stylingMode: 'outlined',
      displayExpr: 'name',
      valueExpr: 'id',
    } as DxSelectBoxTypes.Properties;

    const trainingLocationTypeOptionsDataSource = [
      {
        id: TrainingLocationTypeModel.Address,
        name: this.translateService.instant(
          'COMMON.FIELDS.EVENT-TYPES.FACE-TO-FACE'
        ),
      },
      {
        id: TrainingLocationTypeModel.Web,
        name: this.translateService.instant('COMMON.FIELDS.EVENT-TYPES.ONLINE'),
      },
    ];

    if (isIntegrationsAvailable) {
      for (let integration of this.integrations) {
        if (integration.type == IntegrationTypeModel.GoToWebinar) {
          trainingLocationTypeOptionsDataSource.push({
            id: TrainingLocationTypeModel.GoToWebinar,
            name: this.translateService.instant(
              'COMMON.FIELDS.EVENT-TYPES.GO-TO-WEBINAR'
            ),
          });
        }
        if (integration.type == IntegrationTypeModel.Webex) {
          trainingLocationTypeOptionsDataSource.push({
            id: TrainingLocationTypeModel.Webex,
            name: this.translateService.instant(
              'COMMON.FIELDS.EVENT-TYPES.WEBEX-WEBINAR'
            ),
          });
        }
      }
    }
    this.trainingLocationTypeOptions = {
      ...defaultSelectOptions,
      showClearButton: true,
      onItemClick: this.onTrainingLocationTypeSelected.bind(this),
      dataSource: trainingLocationTypeOptionsDataSource,
      placeholder: '',
    };

    if (this.editingTrainingId) {
      this.trainingLocationTypeOptions.disabled = true
    };
  }

  setTreeViewValues() {
    this.trainingGroups.forEach((trainingGroup) => {
      if (this.trainingForm.trainingGroupIds.indexOf(trainingGroup.id) > -1) {
        trainingGroup.selected = true;
      }
    });

    this.trainingCategories.forEach((trainingCategory) => {
      if (
        this.trainingForm.trainingCategoryIds.indexOf(trainingCategory.id) > -1
      ) {
        trainingCategory.selected = true;
      }
    });

    this.organizationUnits.forEach(unit => {
      if(this.selectedOrganizationUnitsIds.indexOf(unit.id) > -1) {
        unit.selected = true;
      }
    });

    // this.locations.forEach((location) => {
    //   if (
    //     this.trainingForm.organisationUnitLocationIds.indexOf(location.id) > -1
    //   ) {
    //     location.selected = true;
    //   }
    // });

    // this.departments.forEach((department) => {
    //   if (
    //     this.trainingForm.organisationUnitDepartmentIds.indexOf(department.id) >
    //     -1
    //   ) {
    //     department.selected = true;
    //   }
    // });

    this.trainers.forEach((trainer) => {
      if (this.trainingForm.trainerIds.indexOf(trainer.id) > -1) {
        trainer.selected = true;
      }
    });
  }

  onTrainingLocationTypeSelected() {
    this.trainingForm.trainingLocation.location = '';
    this.trainingForm.trainingLocation.suffix = '';
    this.trainingForm.trainingLocation.placeId = '';
    this.trainingForm.trainingLocation.latitude = null;
    this.trainingForm.trainingLocation.longitude = null;
    this.googleSearchText = '';
  }

  onTrainingTemplateSelected(e: any) {
    this.trainingForm.templateId = e.itemData.id;
    this.loadingService
      .load(this.trainingTemplateService.getTrainingTemplate(e.itemData.id))
      .subscribe((trainingTemplateFull) => {
        this.selectedOrganizationUnitsIds = trainingTemplateFull.organisationUnits.map(unit => unit.id);
        // this.trainingForm.organisationUnitLocationIds =
        //   trainingTemplateFull.organisationUnits
        //     .filter(
        //       (organizationUnit) =>
        //         organizationUnit.type == OrganisationUnitTypeModel.Location
        //     )
        //     .map((organizationUnit) => organizationUnit.id);
        // this.trainingForm.organisationUnitDepartmentIds =
        //   trainingTemplateFull.organisationUnits
        //     .filter(
        //       (organizationUnit) =>
        //         organizationUnit.type == OrganisationUnitTypeModel.Department
        //     )
        //     .map((organizationUnit) => organizationUnit.id);
        this.trainingForm.trainerIds = trainingTemplateFull.trainers.map(
          (trainer) => trainer.id
        );
        this.trainingForm.trainingGroupIds =
          trainingTemplateFull.trainingGroups.map(
            (trainingGroup) => trainingGroup.id
          );
        this.trainingForm.name = trainingTemplateFull.trainingTemplate.name;
        this.trainingForm.description =
          trainingTemplateFull.trainingTemplate.description!;
        this.trainingForm.descriptionExtended =
          trainingTemplateFull.trainingTemplate.descriptionExtended!;
        this.trainingForm.trainingType =
          trainingTemplateFull.trainingTemplate.trainingType;
        this.trainingForm.sendCertificateLink =
          trainingTemplateFull.trainingTemplate.sendCertificateLink;
        this.trainingForm.sendFeedbackLink =
          trainingTemplateFull.trainingTemplate.sendFeedbackLink;
        this.trainingForm.maximumMembers =
          trainingTemplateFull.trainingTemplate.maximumMembers;
        this.trainingForm.emailBody =
          trainingTemplateFull.trainingTemplate.emailBody;
        this.trainingForm.iddTimeMinutes =
          trainingTemplateFull.trainingTemplate.iddTimeMinutes;
        this.trainingForm.iddHours = TimeService.getHoursFromAbsoluteMinutes(
          this.trainingForm.iddTimeMinutes || 0
        );
        this.trainingForm.iddMinutes =
          TimeService.getMinutesFromAbsoluteMinutes(
            this.trainingForm.iddTimeMinutes || 0
          );

        if (trainingTemplateFull.trainingTemplate.trainingLocation != null) {
          this.trainingForm.trainingLocation.locationType =
            trainingTemplateFull.trainingTemplate.trainingLocation.type;
          this.trainingForm.trainingLocation.location =
            trainingTemplateFull.trainingTemplate.trainingLocation.location;
          this.trainingForm.trainingLocation.suffix =
            trainingTemplateFull.trainingTemplate.trainingLocation.suffix!;
          this.trainingForm.trainingLocation.placeId =
            trainingTemplateFull.trainingTemplate.trainingLocation.placeId!;
          this.trainingForm.trainingLocation.latitude =
            trainingTemplateFull.trainingTemplate.trainingLocation.latitude!;
          this.trainingForm.trainingLocation.longitude =
            trainingTemplateFull.trainingTemplate.trainingLocation.longitude!;
          this.googleSearchText =
            trainingTemplateFull.trainingTemplate.trainingLocation.location;
        }

        if (trainingTemplateFull.trainingTemplate.imageUrl) {
          this.loadingService
            .load(
              this.fileUtilService.avatarUrlToBase64(
                trainingTemplateFull.trainingTemplate.imageUrl!
              )
            )
            .subscribe((base64) => {
              const mimeType = this.getImageMimeType(
                trainingTemplateFull.trainingTemplate.imageUrl!
              );
              this.trainingForm.imageBase64 = `data:${mimeType};base64,${base64}`;
            });
        }

        this.setTreeViewValues();
      });
  }

  onTrainingGroupSelected(e: any) {
    if (e.node.selected) {
      this.trainingForm.trainingGroupIds = [
        ...this.trainingForm.trainingGroupIds,
        e.node.key,
      ];
    } else {
      this.trainingForm.trainingGroupIds =
        this.trainingForm.trainingGroupIds.filter((id) => id !== e.node.key);
    }
  }

  onTrainingCategorySelected(e: any) {
    if (e.node.selected) {
      this.trainingForm.trainingCategoryIds = [
        ...this.trainingForm.trainingCategoryIds,
        e.node.key,
      ];
    } else {
      this.trainingForm.trainingCategoryIds =
        this.trainingForm.trainingCategoryIds.filter((id) => id !== e.node.key);
    }
  }

  onOrganizationUnitsSelected(e: any) {
    this.selectedOrganizationUnitsIds = [];

    this.organizationUnits.forEach(unit => {
      if (unit.selected) {
        this.selectedOrganizationUnitsIds.push(unit.id);
      }
    });
  }

  // onDepartmentSelected(e: any) {
  //   if (e.node.selected) {
  //     this.trainingForm.organisationUnitDepartmentIds = [
  //       ...this.trainingForm.organisationUnitDepartmentIds,
  //       e.node.key,
  //     ];
  //   } else {
  //     this.trainingForm.organisationUnitDepartmentIds =
  //       this.trainingForm.organisationUnitDepartmentIds.filter(
  //         (id) => id !== e.node.key
  //       );
  //   }
  // }

  // onLocationSelected(e: any) {
  //   if (e.node.selected) {
  //     this.trainingForm.organisationUnitLocationIds = [
  //       ...this.trainingForm.organisationUnitLocationIds,
  //       e.node.key,
  //     ];
  //   } else {
  //     this.trainingForm.organisationUnitLocationIds =
  //       this.trainingForm.organisationUnitLocationIds.filter(
  //         (id) => id !== e.node.key
  //       );
  //   }
  // }

  onTrainerSelected(e: any) {
    if (e.node.selected) {
      this.trainingForm.trainerIds = [
        ...this.trainingForm.trainerIds,
        e.node.key,
      ];
    } else {
      this.trainingForm.trainerIds = this.trainingForm.trainerIds.filter(
        (id) => id !== e.node.key
      );
    }
  }

  onAvatarUploaded(files: any) {
    const file = files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.trainingForm!.imageBase64 = reader!.result!.toString();
    };
    reader.readAsDataURL(file);
  }

  handleTimePicker(value: { hours: number; minutes: number }) {
    this.trainingForm.iddHours = value.hours;
    this.trainingForm.iddMinutes = value.minutes;

    const trainingTimeGroupConfig =
      validationEngine.getGroupConfig('trainingTimeGroup');
    if (this.trainingForm.trainingType === 0 && trainingTimeGroupConfig) {
      const isValid =
        validationEngine.validateGroup('trainingTimeGroup').isValid;

      if (isValid) {
        this.isTrainingTimeValid = true;
      }
    }
  }

  patchForm(training: TrainingFullModel) {
    if (training.organisationUnits.length === this.organizationUnits.length && this.isViewMode) {
      this.selectedOrganizationUnitsIds = ['0'];
      // @ts-ignore
      this.organizationUnits = [{
        id: '0',
        name: this.translateService.instant('COMMON.ALL'),
      }];
    } else {
      this.selectedOrganizationUnitsIds = training.organisationUnits.map(unit => unit.id);
    }
    // this.trainingForm.organisationUnitLocationIds = training.organisationUnits
    //   .filter(
    //     (organizationUnit) =>
    //       organizationUnit.type == OrganisationUnitTypeModel.Location
    //   )
    //   .map((organizationUnit) => organizationUnit.id);
    // this.trainingForm.organisationUnitDepartmentIds = training.organisationUnits
    //   .filter(
    //     (organizationUnit) =>
    //       organizationUnit.type == OrganisationUnitTypeModel.Department
    //   )
    //   .map((organizationUnit) => organizationUnit.id);
    this.trainingForm.templateId = training.training.templateId;
    this.trainingForm.name = training.training.name;
    this.trainingForm.trainingType = training.training.trainingType;
    this.trainingForm.description = training.training.description!;
    this.trainingForm.descriptionExtended =
      training.training.descriptionExtended!;
    this.trainingForm.trainerIds = training.trainers.map(
      (trainer) => trainer.id
    );
    this.trainingForm.trainingGroupIds = training.trainingGroups.map(
      (trainingGroup) => trainingGroup.id
    );
    this.trainingForm.trainingCategoryIds = training.trainingCategories.map(
      (trainingCategory) => trainingCategory.id
    );
    this.trainingForm.sendCertificateLink =
      training.training.sendCertificateLink;
    this.trainingForm.sendFeedbackLink = training.training.sendFeedbackLink;
    this.trainingForm.sendFeedbackLink = training.training.sendFeedbackLink;
    this.trainingForm.maximumMembers = training.training.maximumMembers;
    this.trainingForm.emailBody = training.training.emailBody;
    this.trainingForm.iddTimeMinutes = training.training.iddTimeMinutes;
    this.trainingForm.trainingLocation.locationType =
      training.training.trainingLocation.type;
    this.googleSearchText = training.training.trainingLocation.location;
    this.trainingForm.trainingLocation.location =
      training.training.trainingLocation.location;
    this.trainingForm.trainingLocation.suffix =
      training.training.trainingLocation.suffix!;
    this.trainingForm.trainingLocation.placeId =
      training.training.trainingLocation.placeId!;
    this.trainingForm.trainingLocation.latitude =
      training.training.trainingLocation.latitude!;
    this.trainingForm.trainingLocation.longitude =
      training.training.trainingLocation.longitude!;
    this.trainingForm.lernart = training.training.lernart;
    this.trainingForm.lerninhalt = training.training.lerninhalt;

    this.trainingForm.iddHours = TimeService.getHoursFromAbsoluteMinutes(
      this.trainingForm.iddTimeMinutes || 0
    );
    this.trainingForm.iddMinutes = TimeService.getMinutesFromAbsoluteMinutes(
      this.trainingForm.iddTimeMinutes || 0
    );

    if (training.training.imageUrl) {
      this.loadingService
        .load(
          this.fileUtilService.avatarUrlToBase64(training.training.imageUrl!)
        )
        .subscribe((base64) => {
          const mimeType = this.getImageMimeType(training.training.imageUrl!);
          this.trainingForm.imageBase64 = `data:${mimeType};base64,${base64}`;
        });
    }
    if (!this.isViewMode) {
      this.trainingDateSelectionComponent.patchDates(training.dates);
    }

    this.setTreeViewValues();
  }

  getImageMimeType(url: string) {
    const extension = url.split('.').pop()?.split(/\#|\?/)[0].toLowerCase();

    switch (extension) {
      case 'png':
        return 'image/png';
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'gif':
        return 'image/gif';
      default:
        return 'image/jpeg';
    }
  }

  refreshComponent() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/training/edit/' + this.editingTrainingId]);
    });
  }

  getGooglePlaces(e: any) {
    this.isTrainingLocationValid = true;
    this.googlePlaceinputChanged.next(e.value);
  }

  setLocationPlace(e: any) {
    this.loadingService
      .load(this.mapsService.getDetails(e.itemData.place_id))
      .subscribe((details) => {
        const location = details.formatted_address.startsWith(details.name)
          ? details.formatted_address
          : details.name + ', ' + details.formatted_address;
        this.trainingForm.trainingLocation.location = location;
        this.trainingForm.trainingLocation.placeId = details.place_id;
        this.trainingForm.trainingLocation.latitude =
          details.geometry.location.lat;
        this.trainingForm.trainingLocation.longitude =
          details.geometry.location.lng;
        this.trainingForm.trainingLocation = {
          ...this.trainingForm.trainingLocation,
        };
      });
  }

  autocompleteSearchFilter(e: any) {
    return this.googleSearchText;
  }

  onMenuItemClick(event: DxDropDownButtonTypes.ItemClickEvent) {
    switch (event.itemData.value) {
      case SecondaryButtonType.CopyLink:
        this.onCopyLinkClick(this.training as TrainingFullModel);
        break;
      case SecondaryButtonType.DownloadMemberList:
        this.onDownloadMemberListClick();
        break;
      case SecondaryButtonType.DownloadCompletedEventMemberList:
        this.onDownloadCompletedEventMemberListClick();
        break;
      case SecondaryButtonType.DownloadIcs:
        this.onDownloadIcsClick();
        break;
      case SecondaryButtonType.SendInfo:
        this.onSendInfoClick();
        break;
      case SecondaryButtonType.Edit:
        this.onEditClick();
        break;
      case SecondaryButtonType.Cancel:
        this.openCancelTraining();
        break;
      case SecondaryButtonType.Delete:
        this.openDeleteTrainingConfirmationModal();
        break;
    }
  }

  onPrimaryButtonClick() {
    switch (this.primaryButton) {
      case PrimaryButtonType.AddAttendees:
        this.onInviteClick();
        break;
      case PrimaryButtonType.CompleteTraining:
        this.onCompleteClick();
        break;
      case PrimaryButtonType.ShowFeedback:
        this.onShowFeedbackClick();
        break;
      case PrimaryButtonType.DownloadCompletedEventMemberList:
        this.onDownloadCompletedEventMemberListClick();
        break;
    }
  }

  onEditClick() {
    this.router.navigate(['training', 'edit', this.editingTrainingId]);
  }

  onInviteClick(): void {
    this.router.navigate(['training', 'invite', this.editingTrainingId]);
  }

  onCompleteClick(): void {
    this.router.navigate(['training', 'complete', this.editingTrainingId]);
  }

  onShowFeedbackClick(): void {
    this.router.navigate(['training', 'feedback', this.editingTrainingId]);
  }

  onDownloadCompletedEventMemberListClick(): void {
    this.completedEventService.downloadCompletedEventMemberListByTrainingId(
      this.editingTrainingId
    );
  }

  onCopyLinkClick(training: TrainingFullModel): void {
    const url =
      window.location.origin +
      environment.myInvitationBaseUrl +
      '/' +
      training.training.key;
    if (this.clipboard.copy(url)) {
      this.toastrService.success('Link in der Zwischenablage gespeichert');
    } else {
      this.toastrService.warning('Link konnte nicht gespeichert werden');
    }
  }

  onDownloadMemberListClick(): void {
    const sub = this.loadingService
      .load(this.trainingService.getMemberListPdf(this.editingTrainingId))
      .subscribe({
        next: (pdf) => this.fileUtilService.downloadFile(pdf),
        error: () => this.toastrService.error('Es ist ein Fehler aufgetreten'),
      });
    this.subs.add(sub);
  }

  onDownloadIcsClick(): void {
    const sub = this.loadingService
      .load(this.trainingService.getIcs(this.editingTrainingId))
      .subscribe({
        next: (ics) => this.fileUtilService.downloadFile(ics),
        error: () => this.toastrService.error('Es ist ein Fehler aufgetreten'),
      });
    this.subs.add(sub);
  }

  onSendInfoClick(): void {
    this.router.navigate(['training', 'message', this.editingTrainingId]);
  }

  openCancelTraining() {
    this.showCancelTrainingModal = true;
  }

  onCancelTrainingClick({
    cancelInformationText,
  }: {
    cancelInformationText: string | null;
  }): void {
    this.loadingService
      .load(
        this.trainingService.cancelTraining(this.editingTrainingId, {
          cancelInformationText,
        })
      )
      .subscribe({
        next: () => {
          this.toastrService.success('Schulung abgesagt');
          this.navigateBack();
        },
        error: () => {
          this.toastrService.error('Schulung konnte nicht abgesagt werden');
        },
      });
  }

  openDeleteTrainingConfirmationModal() {
    this.modalConfirmationOptions = {
      hideUndo: true,
      submitButtonText: this.translateService.instant('COMMON.YES'),
      message: this.translateService.instant(
        'TRAINING.DELETE-TRAINING-DIALOG.TEXT'
      ),
      dismissButtonText: this.translateService.instant('COMMON.NO'),
    };
    this.showDeleteTrainingModal = true;
  }

  onDeleteTrainingClick(): void {
    this.loadingService
      .load(this.trainingService.deleteTraining(this.editingTrainingId))
      .subscribe({
        next: () => {
          this.toastrService.success('Schulung gelöscht');
          this.navigateBack();
        },
        error: () => {
          this.toastrService.error('Schulung konnte nicht gelöscht werden');
        },
      });
  }

  showEmployeeModal(tab: number) {
    this.isEmployeePopupVisible = true;
    this.employeePopupTab = tab;
  }

  onEmployeePopupClose() {
    this.isEmployeePopupVisible = false;
  }

  areDatesOnSameDay(start: string, end: string) {
    return (
      new Date(start).toLocaleDateString() ===
      new Date(end).toLocaleDateString()
    );
  }

  validateTrainingTime(e: any) {
    return e.value < 15 ? false : true;
  }

  onFutureDateCheck(isAllInFuture: any) {
    if (isAllInFuture) {
    } else if (!isAllInFuture) {
      if (this.trainingForm.trainingLocation.locationType != 0 && this.trainingForm.trainingLocation.locationType != 1) {
        this.trainingForm.trainingLocation = {
          locationType: null,
          location: null,
          suffix: null,
          placeId: null,
          latitude: null,
          longitude: null,
        };
      }
    }

    this.setLocationTypeOptions(isAllInFuture);
  }
}
