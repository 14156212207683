import {Component, OnInit} from '@angular/core';
import {LoadingService} from "../../services/loading.service";
import {IntegrationsService} from "../../services/integration/integrations.service";
import {BehaviorSubject, Observable} from "rxjs";
import {IntegrationModel} from "../../models/integration/integration.model";
import {map, shareReplay, switchMap} from "rxjs/operators";
import {IntegrationTypeModel} from "../../models/integration/integration-type.model";
import {ToastrService} from "ngx-toastr";
import {TrainingLocationTypeModel} from "../../models/training/training-location-type.model";

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent implements OnInit {

    private reloadIntegrations$ = new BehaviorSubject(null);
    integrations$: Observable<IntegrationModel[]>;
    webexIntegration$: Observable<IntegrationModel | undefined>;
    goToWebinarIntegration$: Observable<IntegrationModel | undefined>;
    disconnectModalVisible: boolean = false;
    disconnectType: string | null = null;

    constructor(private loadingService: LoadingService, private integrationService: IntegrationsService,
                private toastrService: ToastrService) {
        this.integrations$ = this.reloadIntegrations$.pipe(
            switchMap(() => this.loadingService.load(this.integrationService.getIntegrations())),
            shareReplay(1)
        );
        this.webexIntegration$ = this.integrations$.pipe(
            map(integrations => integrations.find(integration => integration.type == IntegrationTypeModel.Webex))
        );
        this.goToWebinarIntegration$ = this.integrations$.pipe(
            map(integrations => integrations.find(integration => integration.type == IntegrationTypeModel.GoToWebinar))
        );
    }

    ngOnInit(): void {
    }

    // Webex
    integrateWebex(): void {
        const obs = this.integrationService.getOAuthUrl(TrainingLocationTypeModel.Webex, IntegrationsService.webexRedirectUrl);
        this.loadingService.load(obs).subscribe(url =>
            window.location.href = url
        );
    }

    disconnectWebex(): void {
        this.loadingService.load(this.integrationService.disconnect(TrainingLocationTypeModel.Webex))
            .subscribe(() => {
                this.toastrService.success('Webex Integration aufgehoben');
                this.refreshIntegrations();
            });
    }

    // GoToWebinar
    integrateGoToWebinar(): void {
        const obs = this.integrationService.getOAuthUrl(TrainingLocationTypeModel.GoToWebinar, IntegrationsService.goToWebinarRedirectUrl);
        this.loadingService.load(obs).subscribe(url =>
            window.location.href = url
        );
    }

    disconnectGoToWebinar(): void {
        this.loadingService.load(this.integrationService.disconnect(TrainingLocationTypeModel.GoToWebinar))
            .subscribe(() => {
                this.toastrService.success('GoToWebinar Integration aufgehoben');
                this.refreshIntegrations();
            });
    }

    showDisconnectModal(type: string): void {
        this.disconnectType = type;
        this.disconnectModalVisible = true;
    }

    closeDisconnectModal(): void {
        this.disconnectModalVisible = false;
        this.disconnectType = null;
    }

    disconnectIntegration(): void {
        if (this.disconnectType == 'webex') {
            this.disconnectWebex();
        } else if (this.disconnectType == 'gotowebinar') {
            this.disconnectGoToWebinar();
        }
        this.disconnectType = null;
        this.disconnectModalVisible = false;
    }

    private refreshIntegrations(): void {
        this.reloadIntegrations$.next(null);
    }
}
