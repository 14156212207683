import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Configuration} from "../../assets/Configuration";
import {TrainingCategoryModel} from "../models/training-category.model";
import {TrainingCategoryCreationAndUpdateModel} from "../models/training-category-creation-and-update.model";

@Injectable({
  providedIn: 'root'
})
export class TrainingCategoryService {

  constructor(private httpClient: HttpClient) {
  }

  public getAllTrainingCategories(): Observable<TrainingCategoryModel[]> {
    return this.httpClient.get<TrainingCategoryModel[]>(Configuration.getApiBaseUrl() + "/trainingcategory/");
  }

  public getTrainingCategory(id: string): Observable<TrainingCategoryModel> {
    return this.httpClient.get<TrainingCategoryModel>(Configuration.getApiBaseUrl() + "/trainingcategory/" + id);
  }

  public addTrainingCategory(model: TrainingCategoryCreationAndUpdateModel): Observable<TrainingCategoryModel> {
    return this.httpClient.post<TrainingCategoryModel>(Configuration.getApiBaseUrl() + "/trainingcategory/", model);
  }

  public updateTrainingCategory(id: string, model: TrainingCategoryCreationAndUpdateModel): Observable<TrainingCategoryModel> {
    return this.httpClient.post<TrainingCategoryModel>(Configuration.getApiBaseUrl() + "/trainingcategory/" + id, model);
  }

  public deleteTrainingCategory(id: string): Observable<void> {
    return this.httpClient.delete<void>(Configuration.getApiBaseUrl() + "/trainingcategory/" + id);
  }

}
