import { AuthorizationService } from './../../../services/authorization.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Configuration } from '../../../../assets/Configuration';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { Observable, Subscription } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { OrganisationUnitTypeModel } from 'src/app/models/organisation-unit/organisation-unit-type.model';
import { OrganisationUnitModel } from 'src/app/models/organisation-unit/organisation-unit.model';
import { TrainingGroupService } from 'src/app/services/training-group.service';
import { OrganisationUnitService } from 'src/app/services/organisation-unit.service';
import DataSource from 'devextreme/data/data_source';
import { ViewType } from 'devextreme/ui/scheduler';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-training-calendar',
  templateUrl: './training-calendar.component.html',
  styleUrls: ['./training-calendar.component.scss'],
})
export class TrainingCalendarComponent implements OnInit {
  private subs = new Subscription();

  schedulerData: any;
  currentDate: Date = new Date();
  currentView: ViewType = 'month';
  year: string = new Date().getFullYear().toString();
  accessToken!: string;

  filterForm = new UntypedFormGroup({
    organisationUnitLocationIds: new UntypedFormControl(),
    trainingGroupIds: new UntypedFormControl(),
  });

  @ViewChild('dayCellTmpl') dayCellTmpl!: ElementRef<HTMLElement>;

  private organisationUnits$: Observable<OrganisationUnitModel[]> =
    this.organisationUnitService.getAllOrganisationUnits().pipe(shareReplay(1));
  locations$ = this.organisationUnits$.pipe(
    map((organisationUnits) =>
      organisationUnits.filter(
        (organisationUnit) =>
          organisationUnit.type == OrganisationUnitTypeModel.Location
      )
    )
  );
  trainingGroups$ = this.trainingGroupService.getAllTrainingGroups();

  constructor(
    private authorizationService: AuthorizationService,
    private trainingGroupService: TrainingGroupService,
    private organisationUnitService: OrganisationUnitService,
    readonly translateService: TranslateService,
    private router: Router
  ) {
    const url = Configuration.getApiBaseUrl() + '/trainingcalendar';

    const sub = this.authorizationService.accessToken$
      .pipe(
        filter((accessToken: string | null) => accessToken != null),
        map((accessToken: string | null) => accessToken as string)
      )
      .subscribe((accessToken) => {
        this.accessToken = accessToken;
        //this.setSchedulerData();
        this.schedulerData = new DataSource({
          store: AspNetData.createStore({
            key: 'Id',
            loadUrl: `${url}`,
            onBeforeSend(method, ajaxOptions) {
              ajaxOptions.headers = {
                Authorization: 'Bearer ' + accessToken,
              };
            },
          }),
        });
      });
    this.subs.add(sub);
    this.filterForm.valueChanges.subscribe(() => this.updateSchedulerData());
  }

  ngOnInit(): void {}

  // setSchedulerData(): void {
  //   const url =
  //     Configuration.getApiBaseUrl() + `/trainingcalendar/by-year/${this.year}`;
  //   const token = this.accessToken;

  //   this.schedulerData = new DataSource({
  //     store: AspNetData.createStore({
  //       key: 'Id',
  //       loadUrl: `${url}`,
  //       onBeforeSend(method, ajaxOptions) {
  //         ajaxOptions.headers = {
  //           Authorization: 'Bearer ' + token,
  //         };
  //       },
  //     }),
  //   });
  // }

  updateSchedulerData(): void {
    const organisationUnitLocationFilter = this.filterForm.controls
      .organisationUnitLocationIds.value
      ? this.filterForm.controls.organisationUnitLocationIds.value
      : [];
    const trainingGroupFilter = this.filterForm.controls.trainingGroupIds.value
      ? this.filterForm.controls.trainingGroupIds.value
      : [];

    const locationFilterExpression: any[] = [];
    const trainingGroupFilterExpression: any[] = [];

    for (let i = 0; i < organisationUnitLocationFilter.length; i++) {
      locationFilterExpression.push([
        'LocationIds',
        '=',
        organisationUnitLocationFilter[i],
      ]);
      if (i != organisationUnitLocationFilter.length - 1) {
        locationFilterExpression.push('or');
      }
    }
    for (let i = 0; i < trainingGroupFilter.length; i++) {
      trainingGroupFilterExpression.push([
        'TrainingGroupIds',
        '=',
        trainingGroupFilter[i],
      ]);
      if (i != trainingGroupFilter.length - 1) {
        trainingGroupFilterExpression.push('or');
      }
    }

    if (
      locationFilterExpression.length > 0 &&
      trainingGroupFilterExpression.length > 0
    ) {
      this.schedulerData.filter([
        locationFilterExpression,
        'and',
        trainingGroupFilterExpression,
      ]);
    } else if (locationFilterExpression.length > 0) {
      this.schedulerData.filter(locationFilterExpression);
    } else {
      this.schedulerData.filter(trainingGroupFilterExpression);
    }
    this.schedulerData.load();
  }

  customizeDateNavigatorText = (e: any) => {
    const startDay = e.startDate.getDate();
    const currentLanguage = this.translateService.currentLang;

    const endDay = e.endDate.getDate();
    const shortMonAndYear = e.endDate.toLocaleString(currentLanguage, {
      month: 'short',
      year: 'numeric',
    });

    if (this.currentView === 'week') {
      const days = startDay + '-' + endDay;
      return days + ' ' + shortMonAndYear;
    }
    if (this.currentView === 'month') {
      return e.startDate.toLocaleString(currentLanguage, {
        year: 'numeric',
        month: 'long',
      });
    }
    return startDay + ' ' + shortMonAndYear;
  };

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  navigateToTraining(event: MouseEvent, trainingId: string) {
    event.stopPropagation();
    this.router.navigate(['/my-trainings/training', trainingId]);
  }

  formatTime(time: string | null) {
    if (!time) {
      return '';
    }

    const minutes = time.split(':')[1];
    if (minutes == '00') {
      return time.split(':')[0];
    } else return time;
  }
}
