<ng-content></ng-content>

<div class="side-nav">
  <dx-tree-view
    [items]="items"
    keyExpr="path"
    selectionMode="single"
    [focusStateEnabled]="false"
    expandEvent="click"
    (onItemClick)="onItemClick($event)"
    width="100%"
  >
    <div *dxTemplate="let item of 'item'">
      <i
        *ngIf="item.icon"
        class="dx-icon dx-svg-icon"
        [innerHTML]="getTrustedSvg(item.icon)"
      ></i>
      <span>{{ item.text | translate }}</span>
    </div>
  </dx-tree-view>
</div>
