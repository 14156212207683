<dx-popup
  [width]="450"
  [height]="300"
  [showTitle]="true"
  [dragEnabled]="false"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
  class="modal"
  [classList]="'modal'"
  [copyRootClassesToWrapper]="false"
  [visible]="showModal"
  (onHidden)="closeModal()"
>
  <div *dxTemplate="let data of 'content'">
    <div class="response-message">
      <img class="checkmark-icon" src="assets/img/checkmark.jpg" />
      <div>
        {{ "MY-TRAININGS.TRAINING-INVITE.ACCEPTED-AUTOMATICALLY" | translate }}
      </div>
      <div class="response-cta">
        <dx-button
          [text]="'COMMON.CLOSE' | translate"
          type="normal"
          (click)="closeModal()"
        ></dx-button>
      </div>
    </div>
  </div>
</dx-popup>
