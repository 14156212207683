<div class="training-template-form">
  <app-page>
    <div class="top-cta">
      <dx-button
        [text]="'COMMON.BACK' | translate"
        icon="back"
        type="default"
        class="integration-btn"
        (click)="navigateBack()"
      >
      </dx-button>
      <dx-button
        *ngIf="!isViewMode"
        text="{{ 'COMMON.SAVE' | translate }}"
        type="normal"
        (click)="onFormSubmit()"
      ></dx-button>
      <dx-drop-down-button
        *ngIf="isViewMode"
        [splitButton]="true"
        [text]="'TRAINING-TEMPLATE.TITLE-EDIT' | translate"
        stylingMode="contained"
        type="normal"
        [dropDownOptions]="{ width: 230 }"
        (onButtonClick)="onEditClick()"
      >
        <dxi-item (click)="duplicateTemplate()"
          >{{ "TRAINING-TEMPLATE.FORM.DUPLICATE" | translate }}
        </dxi-item>
      </dx-drop-down-button>
    </div>
    <app-page-title
      [title]="
        isViewMode
          ? ('TRAINING-TEMPLATE.FORM.TRAINING-TEMPLATE' | translate)
          : editingTrainingTemplateId
          ? ('TRAINING-TEMPLATE.TITLE-EDIT' | translate)
          : ('TRAINING-TEMPLATE.TITLE-ADD' | translate)
      "
    ></app-page-title>
    <div class="row">
      <div class="col-md-12 col-lg-5">
        <app-panel class="avatar-panel">
          <div id="avatar-upload" class="uploader-container">
            <img
              class="user-avatar"
              [src]="trainingTemplateForm.imageBase64"
              *ngIf="trainingTemplateForm.imageBase64"
            />
            <div
              class="uploader-preview-container"
              *ngIf="!trainingTemplateForm.imageBase64"
            >
              <img src="assets/img/upload.jpg" />
              <span class="preview-title">{{
                "COMMON.UPLOAD-IMAGE" | translate
              }}</span>
              <span class="preview-description">{{
                "COMMON.UPLOAD-IMAGE-SIZE" | translate
              }}</span>
            </div>
          </div>
          <dx-file-uploader
            #fileUploader
            dialogTrigger="#avatar-upload"
            dropZone="#avatar-upload"
            [multiple]="false"
            [allowedFileExtensions]="['.jpg', '.jpeg', '.gif', '.png']"
            uploadMode="useForm"
            [visible]="false"
            (valueChange)="onAvatarUploaded($event)"
            [readOnly]="isViewMode"
          ></dx-file-uploader>
        </app-panel>
      </div>
      <div class="col-md-12 col-lg-7">
        <app-panel
          class="training-template-panel"
          [title]="
            isViewMode
              ? ('TRAINING-TEMPLATE.FORM.TITLE-DESCRIPTION' | translate)
              : null
          "
        >
          <dx-form
            #descriptionForm
            labelMode="floating"
            [showColonAfterLabel]="false"
            [formData]="trainingTemplateForm"
            [readOnly]="isViewMode"
          >
            <dxi-item dataField="name">
              <dxo-label [text]="'COMMON.FIELDS.TITLE' | translate"></dxo-label>
              <dxi-validation-rule
                type="required"
                [message]="'VALIDATIONS.TITLE-REQUIRED' | translate"
              ></dxi-validation-rule>
            </dxi-item>
            <dxi-item
              dataField="description"
              editorType="dxTextArea"
              [editorOptions]="{
                height: 100
              }"
            >
              <dxo-label
                [text]="'COMMON.FIELDS.DESCRIPTION' | translate"
              ></dxo-label>
            </dxi-item>
          </dx-form>
        </app-panel>
      </div>
    </div>
    <div>
      <app-panel [title]="'TRAINING.FORM.EXTENDED-DESCRIPTION' | translate">
        <dx-form
          labelMode="floating"
          [showColonAfterLabel]="false"
          [formData]="trainingTemplateForm"
          [readOnly]="isViewMode"
        >
          <dxi-item>
            <dxo-label [visible]="false"></dxo-label>
            <div *dxTemplate>
              <dx-html-editor
                height="500px"
                [(value)]="trainingTemplateForm.descriptionExtended"
                [readOnly]="isViewMode"
              >
                <dxo-toolbar *ngIf="!isViewMode" [multiline]="true">
                  <dxi-item name="undo"></dxi-item>
                  <dxi-item name="redo"></dxi-item>
                  <dxi-item name="separator"></dxi-item>
                  <dxi-item
                    name="size"
                    [acceptedValues]="[
                      '8pt',
                      '10pt',
                      '12pt',
                      '14pt',
                      '18pt',
                      '24pt',
                      '36pt'
                    ]"
                  ></dxi-item>
                  <dxi-item name="separator"></dxi-item>
                  <dxi-item name="bold"></dxi-item>
                  <dxi-item name="italic"></dxi-item>
                  <dxi-item name="strike"></dxi-item>
                  <dxi-item name="underline"></dxi-item>
                  <dxi-item name="separator"></dxi-item>
                  <dxi-item name="alignLeft"></dxi-item>
                  <dxi-item name="alignCenter"></dxi-item>
                  <dxi-item name="alignRight"></dxi-item>
                  <dxi-item name="alignJustify"></dxi-item>
                  <dxi-item name="separator"></dxi-item>
                  <dxi-item name="orderedList"></dxi-item>
                  <dxi-item name="bulletList"></dxi-item>
                  <dxi-item name="separator"></dxi-item>
                  <dxi-item
                    name="header"
                    [acceptedValues]="[false, 1, 2, 3, 4, 5]"
                  ></dxi-item>
                  <dxi-item name="separator"></dxi-item>
                  <dxi-item name="color"></dxi-item>
                  <dxi-item name="background"></dxi-item>
                  <dxi-item name="separator"></dxi-item>
                  <dxi-item name="link"></dxi-item>
                </dxo-toolbar>
              </dx-html-editor>
            </div>
          </dxi-item>
        </dx-form>
      </app-panel>
    </div>
    <div>
      <app-panel
        [title]="'TRAINING-TEMPLATE.FORM.TRAINING-SETTINGS' | translate"
      >
        <dx-form
          #settingsForm
          labelMode="floating"
          [showColonAfterLabel]="false"
          [formData]="trainingTemplateForm"
          [readOnly]="isViewMode"
        >
          <dxi-item itemType="group">
            <dxo-col-count-by-screen
              [xs]="1"
              [sm]="1"
              [md]="1"
              [lg]="2"
            ></dxo-col-count-by-screen>
            <dxi-item
              itemType="group"
              [caption]="
                !isViewMode
                  ? ('TRAINING-TEMPLATE.FORM.GENERAL-SETTINGS' | translate)
                  : null
              "
            >
              <dxi-item>
                <dxo-label
                  [text]="'COMMON.FIELDS.TRAINERS' | translate"
                ></dxo-label>
                <div *dxTemplate>
                  <dx-drop-down-box
                    [(value)]="trainingTemplateForm.trainerIds"
                    valueExpr="id"
                    displayExpr="name"
                    [label]="'COMMON.FIELDS.TRAINERS' | translate"
                    [dataSource]="trainers"
                    [readOnly]="isViewMode"
                    labelMode="floating"
                    stylingMode="outlined"
                  >
                    <div *dxTemplate="let data of 'content'">
                      <dx-tree-view
                        [searchEnabled]="true"
                        [dataSource]="trainers"
                        dataStructure="plain"
                        keyExpr="id"
                        selectionMode="multiple"
                        showCheckBoxesMode="normal"
                        [selectNodesRecursive]="false"
                        displayExpr="name"
                        [selectByClick]="true"
                        (onItemSelectionChanged)="onTrainerSelected($event)"
                      >
                      </dx-tree-view>
                    </div>
                  </dx-drop-down-box>
                </div>
              </dxi-item>
              <dxi-item
                [editorOptions]="trainingTypeOptions"
                dataField="trainingType"
                editorType="dxSelectBox"
              >
                <dxi-validation-rule
                  type="required"
                  [message]="'VALIDATIONS.TRAINING-TYPE-REQUIRED' | translate"
                ></dxi-validation-rule>
                <dxo-label
                  [text]="'COMMON.FIELDS.TRAINING-TYPE' | translate"
                ></dxo-label>
              </dxi-item>
              <dxi-item *ngIf="trainingTemplateForm.trainingType === 0">
                <dxo-label
                  [text]="'COMMON.FIELDS.TRAINING-TIME' | translate"
                ></dxo-label>
                <div *dxTemplate>
                  <app-time-picker
                    [isInvalid]="false"
                    (timeChanged)="handleTimePicker($event)"
                    [defaultValue]="{
                      hours: trainingTemplateForm.iddHours!,
                      minutes: trainingTemplateForm.iddMinutes!
                    }"
                  ></app-time-picker>
                </div>
              </dxi-item>
              <dxi-item dataField="maximumMembers" editorType="dxNumberBox">
                <dxo-label
                  [text]="'COMMON.FIELDS.MAXIMUM-PARTICIPANTS' | translate"
                ></dxo-label>
              </dxi-item>
            </dxi-item>
            <dxi-item
              itemType="group"
              [caption]="
                !isViewMode
                  ? ('TRAINING-TEMPLATE.FORM.APPLICABILITY-VISIBILITY'
                    | translate)
                  : null
              "
            >
              <dxi-item>
                <dxo-label
                  [text]="'COMMON.FIELDS.LOCATIONS-DEPARTMENTS' | translate"
                ></dxo-label>
                <div *dxTemplate>
                  <dx-drop-down-box
                    [(value)]="trainingTemplateForm.organisationUnitLocationIds"
                    valueExpr="id"
                    displayExpr="name"
                    [label]="'COMMON.FIELDS.LOCATIONS-DEPARTMENTS' | translate"
                    [dataSource]="organizationUnits"
                    [readOnly]="isViewMode"
                    labelMode="floating"
                    stylingMode="outlined"
                  >
                    <div *dxTemplate="let data of 'content'">
                      <dx-tree-view
                        [searchEnabled]="true"
                        [dataSource]="organizationUnits"
                        dataStructure="plain"
                        keyExpr="id"
                        selectionMode="multiple"
                        showCheckBoxesMode="normal"
                        [selectNodesRecursive]="true"
                        displayExpr="name"
                        [selectByClick]="true"
                        (onItemSelectionChanged)="onLocationSelected($event)"
                      >
                      </dx-tree-view>
                    </div>
                  </dx-drop-down-box>
                </div>
              </dxi-item>
              <dxi-item>
                <dxo-label
                  [text]="'COMMON.FIELDS.TRAINING-GROUPS' | translate"
                ></dxo-label>
                <div *dxTemplate>
                  <dx-drop-down-box
                    [(value)]="trainingTemplateForm.trainingGroupIds"
                    [inputAttr]="{ 'aria-label': 'Owner' }"
                    valueExpr="id"
                    displayExpr="name"
                    [label]="'COMMON.FIELDS.TRAINING-GROUPS' | translate"
                    [dataSource]="trainingGroups"
                    [readOnly]="isViewMode"
                    labelMode="floating"
                    stylingMode="outlined"
                  >
                    <div *dxTemplate="let data of 'content'">
                      <dx-tree-view
                        [searchEnabled]="true"
                        [dataSource]="trainingGroups"
                        dataStructure="plain"
                        keyExpr="id"
                        selectionMode="multiple"
                        showCheckBoxesMode="normal"
                        [selectNodesRecursive]="false"
                        displayExpr="name"
                        [selectByClick]="true"
                        (onItemSelectionChanged)="
                          onTrainingGroupSelected($event)
                        "
                      >
                      </dx-tree-view>
                    </div>
                  </dx-drop-down-box>
                </div>
              </dxi-item>
            </dxi-item>
          </dxi-item>
        </dx-form>
        <div class="row checkboxes-row">
          <div class="col-md-12 col-lg-6">
            <dx-form
              [colCountByScreen]="{ lg: 2, md: 1 }"
              [formData]="trainingTemplateForm"
              [showColonAfterLabel]="false"
              labelMode="static"
              labelLocation="left"
              [readOnly]="isViewMode"
            >
              <dxi-item dataField="sendCertificateLink">
                <dxo-label
                  [text]="
                    'COMMON.FIELDS.CERTIFICATE-LINK-IN-COMPLETION-MAIL'
                      | translate
                  "
                ></dxo-label>
              </dxi-item>
              <dxi-item dataField="sendFeedbackLink">
                <dxo-label
                  [text]="
                    'COMMON.FIELDS.FEEDBACK-LINK-IN-COMPLETION-MAIL' | translate
                  "
                ></dxo-label>
              </dxi-item>
            </dx-form>
          </div>
        </div>
      </app-panel>
    </div>
    <div>
      <app-panel
        class="training-template-location-panel"
        [title]="'COMMON.FIELDS.TRAINING-LOCATION' | translate"
      >
        <div class="row">
          <div class="col-md-12 col-lg-6">
            <dx-form
              #locationForm
              [formData]="trainingTemplateForm"
              [showColonAfterLabel]="false"
              labelMode="floating"
              [readOnly]="isViewMode"
            >
              <dxi-item
                [editorOptions]="trainingLocationTypeOptions"
                dataField="trainingLocation.locationType"
                editorType="dxSelectBox"
              >
                <dxi-validation-rule
                  type="required"
                  [message]="'VALIDATIONS.EVENT-TYPE-REQUIRED' | translate"
                ></dxi-validation-rule>
                <dxo-label
                  [text]="'COMMON.FIELDS.EVENT-TYPE' | translate"
                ></dxo-label>
              </dxi-item>
              <dxi-item
                *ngIf="
                  trainingTemplateForm.trainingLocation!.locationType === 0
                "
              >
                <div *dxTemplate>
                  <dx-autocomplete
                    [(text)]="googleSearchText"
                    [searchExpr]="autocompleteSearchFilter"
                    [label]="'COMMON.FIELDS.TRAINING-LOCATION' | translate"
                    labelMode="floating"
                    stylingMode="outlined"
                    [label]="
                      'TRAINING-TEMPLATE.FORM.SEARCH-TRAINING-LOCATION'
                        | translate
                    "
                    [dataSource]="googlePlaces"
                    valueExpr="description"
                    (onValueChanged)="getGooglePlaces($event)"
                    (onItemClick)="setLocationPlace($event)"
                    [readOnly]="isViewMode"
                  >
                  </dx-autocomplete>
                </div>
              </dxi-item>
              <dxi-item
                *ngIf="
                  trainingTemplateForm.trainingLocation!.locationType === 1
                "
                dataField="trainingLocation.location"
              >
                <dxo-label [text]="getTrainingLocationLabel"></dxo-label>
              </dxi-item>
              <dxi-item
                *ngIf="
                  trainingTemplateForm.trainingLocation!.locationType !== null
                "
                dataField="trainingLocation.suffix"
                editorType="dxTextArea"
                [editorOptions]="{
                  height: 100
                }"
              >
                <dxo-label [text]="getTrainingLocationDetailsLabel"></dxo-label>
              </dxi-item>
            </dx-form>
          </div>
          <div class="col-md-12 col-lg-6">
            <div
              *ngIf="
                trainingTemplateForm.trainingLocation!.locationType ==
                TrainingLocationType.Address
              "
            >
              <ng-container
                *ngIf="
                  trainingTemplateForm.trainingLocation!.latitude &&
                  trainingTemplateForm.trainingLocation!.longitude
                "
              >
                <app-google-maps
                  class="my-3"
                  [location]="trainingTemplateForm.trainingLocation"
                ></app-google-maps>
              </ng-container>
            </div>
          </div>
        </div>
      </app-panel>
    </div>
    <div>
      <app-panel
        [title]="'TRAINING-TEMPLATE.FORM.STANDARD-INVITATION-TEXT' | translate"
      >
        <dx-form
          labelLocation="top"
          [showColonAfterLabel]="false"
          [formData]="trainingTemplateForm"
        >
          <dxi-item
            *ngIf="!isViewMode"
            dataField="emailBody"
            label=""
            editorType="dxHtmlEditor"
            [editorOptions]="{
              label: '',
              height: 350,
              toolbar: {
                items: [
                  'undo',
                  'redo',
                  'separator',
                  {
                    name: 'size',
                    acceptedValues: ['8pt', '10pt', '12pt', '14pt', '18pt']
                  },
                  { name: 'font', acceptedValues: ['Arial'] },
                  'separator',
                  'bold',
                  'italic',
                  'strike',
                  'underline',
                  'separator',
                  'alignLeft',
                  'alignCenter',
                  'alignRight',
                  'alignJustify',
                  'separator',
                  'orderedList',
                  'bulletList',
                  'separator',
                  {
                    name: 'header',
                    acceptedValues: [false, 1, 2, 3, 4, 5]
                  },
                  'separator',
                  'background',
                  'separator',
                  'link'
                ]
              }
            }"
          >
            <dxo-label text=" "></dxo-label>
          </dxi-item>
          <dxi-item
            *ngIf="isViewMode"
            dataField="emailBody"
            label=""
            editorType="dxHtmlEditor"
            [editorOptions]="{
              label: '',
              height: 350,
            }"
          >
            <dxo-label text=" "></dxo-label>
          </dxi-item>
        </dx-form>
      </app-panel>
    </div>
  </app-page>
</div>
