import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnInit,
  AfterViewInit,
} from '@angular/core';
import {
  DxTreeViewTypes,
  DxTreeViewComponent,
} from 'devextreme-angular/ui/tree-view';
import { navigation } from '../../app-navigation';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router, Event } from '@angular/router';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements AfterViewInit {
  @ViewChild(DxTreeViewComponent, { static: true }) menu!: DxTreeViewComponent;

  @Output()
  selectedItemChanged = new EventEmitter<DxTreeViewTypes.ItemClickEvent>();

  @Output()
  openMenu = new EventEmitter<any>();

  @Input()
  set selectedItem(value: String) {
    this.selectedItemValue = value;
    this.setSelectedItem();
  }

  @Input()
  userRole!: string;

  get selectedItem(): String {
    return this.selectedItemValue;
  }

  selectedItemValue: String = '';
  items!: any[];

  constructor(private sanitized: DomSanitizer) {
    this.items = navigation;
  }

  ngAfterViewInit(): void {
    if (this.userRole == 'Manager') {
      this.items = navigation.filter((item) => {
        if (item.items) {
          item.items = item.items.filter((subItem: any) => {
            return (
              subItem.text !== 'SIDENAV.MANAGE-TRAININGS' &&
              subItem.text !== 'SIDENAV.MANAGE-ELEARNING' &&
              subItem.text !== 'SIDENAV.ADMINISTRATION'
            );
          });
        }

        return (
          item.text !== 'SIDENAV.SETTINGS'
        );
      });

    } else if (this.userRole == 'Employee') {
      this.items = navigation.filter((item) => {
        if (item.items) {
          item.items = item.items.filter((subItem: any) => {
            return (
              subItem.text !== 'SIDENAV.MANAGE-TRAININGS' &&
              subItem.text !== 'SIDENAV.MANAGE-ELEARNING' &&
              subItem.text !== 'SIDENAV.ADMINISTRATION'
            );
          });
        }

        return (
          item.text !== 'SIDENAV.ORGANIZATION' &&
          item.text !== 'SIDENAV.SETTINGS'
        );
      });
    } else if (this.userRole == 'Trainer') {
      this.items = navigation.filter((item) => {
        if (item.items) {
          item.items = item.items.filter((subItem: any) => {
            return (
              subItem.text !== 'SIDENAV.MANAGE-ELEARNING' &&
              subItem.text !== 'SIDENAV.ADMINISTRATION' &&
              subItem.text !== 'SIDENAV.STRUCTURE' &&
              subItem.text !== 'SIDENAV.EMPLOYEES'
            );
          });
        }

        return (
          item.text !== 'SIDENAV.SETTINGS'
        );
      });
    }

    this.setSelectedItem();
  }

  expandCurrentMenuItem(currentRoute: String) {
    this.menu.instance.selectItem(currentRoute);

    this.items.forEach((item) => {
      if (item.items) {
        item.items!.forEach((nestedItem: any) => {
          if (nestedItem.path === currentRoute) {
            this.menu.instance.expandItem(currentRoute);
          }
        });
      }
    });
  }

  getTrustedSvg(svg: string) {
    return this.sanitized.bypassSecurityTrustHtml(svg);
  }

  setSelectedItem() {
    if (!this.menu.instance) {
      return;
    }

    this.menu.instance.selectItem(this.selectedItemValue);
    this.expandCurrentMenuItem(this.selectedItemValue);

    if (this.selectedItemValue === '/profile') {
      this.menu.instance.unselectAll();
    }
  }

  onItemClick(event: DxTreeViewTypes.ItemClickEvent) {
    this.selectedItemChanged.emit(event);
  }
}
