<div>
  <app-page>
    <app-page-title
      title="{{ 'EXTERNAL-CONTACTS.OVERVIEW.TITLE' | translate }}"
    >
      <!-- <dx-button class="cta-btn" type="default" text="Delete"></dx-button> -->
      <dx-button
        class="cta-btn"
        type="normal"
        icon="add"
        text="{{ 'EXTERNAL-CONTACTS.OVERVIEW.ADD-EEXTERNAL-CONTACT' | translate }}"
        (click)="onAddClick()"
      ></dx-button>
    </app-page-title>
    <app-table
      [data]="contacts"
      [columns]="contactsColumns"
      [showSearch]="true"
      [showGrouping]="true"
      [searchLabel]="'COMMON.SEARCH' | translate"
      [searchPlacehoder]="'EXTERNAL-CONTACTS.OVERVIEW.FIND-EXTERNAL-CONTACT' | translate"
      (onRowClick)="navigateToDetails($event)"
    ></app-table>
  </app-page>
</div>
