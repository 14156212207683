import {
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-invitaion-accepted-modal',
  templateUrl: './invitaion-accepted-modal.component.html',
  styleUrl: './invitaion-accepted-modal.component.scss',
})
export class InvitaionAcceptedModalComponent {
  @Input() showModal = false;
  @Output() showModalChange = new EventEmitter<boolean>();

  constructor() {}

  closeModal() {
    this.showModal = false;
    this.showModalChange.emit(this.showModal);
  }
}
