<div class="organisation-unit-form">
  <app-page>
    <div class="top-cta">
      <dx-button
        [text]="'COMMON.BACK' | translate"
        icon="back"
        type="default"
        class="integration-btn"
        (click)="navigateBack()"
      >
      </dx-button>
      <dx-button
        [text]="'COMMON.SAVE' | translate"
        type="normal"
        (click)="onFormSubmit()"
      >
      </dx-button>
    </div>
    <app-page-title
      [title]="
        editingOrganisationUnitId
          ? ('STRUCTURE.TITLE-EDIT' | translate)
          : ('STRUCTURE.TITLE-ADD' | translate)
      "
    ></app-page-title>
    <div class="row">
      <div class="col-md-12 col-lg-5">
        <app-panel class="avatar-panel">
          <div id="avatar-upload" class="uploader-container">
            <img
              class="avatar-img"
              [src]="organisationUnitForm.image || organisationUnitForm.image"
              *ngIf="organisationUnitForm.image || organisationUnitForm.image"
            />
            <div
              class="uploader-preview-container"
              *ngIf="!organisationUnitForm.image && !organisationUnitForm.image"
            >
              <img src="assets/img/upload.jpg" />
              <span class="preview-title">{{
                "COMMON.UPLOAD-IMAGE" | translate
              }}</span>
              <span class="preview-description">{{
                "COMMON.UPLOAD-IMAGE-SIZE" | translate
              }}</span>
            </div>
          </div>
          <dx-file-uploader
            #fileUploader
            dialogTrigger="#avatar-upload"
            dropZone="#avatar-upload"
            [multiple]="false"
            [allowedFileExtensions]="['.jpg', '.jpeg', '.gif', '.png']"
            uploadMode="useForm"
            [visible]="false"
            (valueChange)="onImageUploaded($event)"
          ></dx-file-uploader>
        </app-panel>
      </div>
      <div class="col-md-12 col-lg-7">
        <app-panel class="organisation-unit-panel">
          <dx-form
            #form
            labelMode="floating"
            [showColonAfterLabel]="false"
            [formData]="organisationUnitForm"
          >
            <dxi-item itemType="group">
              <dxo-col-count-by-screen
                [xs]="1"
                [sm]="1"
                [md]="1"
                [lg]="2"
              ></dxo-col-count-by-screen>
              <dxi-item
                [editorOptions]="typeOptions"
                dataField="type"
                editorType="dxSelectBox"
              >
                <dxi-validation-rule
                  type="required"
                  [message]="'VALIDATIONS.TYPE-REQUIRED' | translate"
                ></dxi-validation-rule>
                <!-- <dxo-label [text]="'COMMON.FIELDS.TYPE' | translate"></dxo-label> -->
              </dxi-item>
              <!-- <dxi-item dataField="parentId">
                <div *dxTemplate>
                  <dx-drop-down-box
                    #parentUnitDropDown
                    [(value)]="organisationUnitForm.parentId"
                    [inputAttr]="{ 'aria-label': 'Owner' }"
                    valueExpr="id"
                    displayExpr="name"
                    [label]="('COMMON.FIELDS.PARENT-UNIT' | translate) + ' *'"
                    [dataSource]="organisationUnits"
                    labelMode="floating"
                    stylingMode="outlined"
                    [disabled]="!!editingOrganisationUnitId"
                    [showClearButton]="true"
                    (onValueChanged)="parentUnitDropDown.instance.close()"
                  >
                    <dx-validator validationGroup="customFields">
                      <dxi-validation-rule
                        *ngIf="
                          !editingOrganisationUnitId ||
                          (editingOrganisationUnitId &&
                            organisationUnitForm.parentId != null)
                        "
                        type="required"
                        [message]="
                          'VALIDATIONS.PARENT-UNIT-REQUIRED' | translate
                        "
                      ></dxi-validation-rule>
                    </dx-validator>
                    <div *dxTemplate="let data of 'content'">
                      <dx-tree-view
                        [searchEnabled]="true"
                        [dataSource]="organisationUnits"
                        keyExpr="id"
                        [selectNodesRecursive]="false"
                        displayExpr="name"
                        (onItemClick)="onOrganisationUnitSelected($event)"
                      >
                      </dx-tree-view>
                    </div>
                  </dx-drop-down-box>
                </div>
              </dxi-item> -->


              <dxi-item>
                <dxo-label
                  [text]="('COMMON.FIELDS.PARENT-UNIT' | translate) + ' *'"
                ></dxo-label>
                <div *dxTemplate>
                  <dx-drop-down-box
                    #parentUnitDropDown
                    [(value)]="organisationUnitForm.parentId"
                    valueExpr="id"
                    displayExpr="name"
                    [label]="'COMMON.FIELDS.LOCATIONS-DEPARTMENTS' | translate"
                    [dataSource]="organisationUnits"
                    [disabled]="editingOrganisationUnit && editingOrganisationUnit.topLevel || false"
                    labelMode="floating"
                    stylingMode="outlined"
                    (onValueChanged)="parentUnitDropDown.instance.close()"
                  >
                    <dx-validator validationGroup="customFields">
                      <dxi-validation-rule
                        *ngIf="
                          !editingOrganisationUnitId ||
                          (editingOrganisationUnitId &&
                            organisationUnitForm.parentId != null)
                        "
                        type="required"
                        [message]="
                          'VALIDATIONS.PARENT-UNIT-REQUIRED' | translate
                        "
                      ></dxi-validation-rule>
                    </dx-validator>
                    <div *dxTemplate="let data of 'content'">
                      <dx-tree-view
                        [searchEnabled]="true"
                        [dataSource]="organisationUnits"
                        dataStructure="plain"
                        keyExpr="id"
                        displayExpr="name"
                        [selectByClick]="true"
                        (onItemSelectionChanged)="onParentUnitsSelected($event)"
                      >
                      </dx-tree-view>
                    </div>
                  </dx-drop-down-box>
                </div>
              </dxi-item>


              
            </dxi-item>
            <dxi-item dataField="name">
              <dxo-label [text]="'COMMON.FIELDS.NAME' | translate"></dxo-label>
              <dxi-validation-rule
                type="required"
                [message]="'VALIDATIONS.NAME-REQUIRED' | translate"
              ></dxi-validation-rule>
            </dxi-item>
            <dxi-item
              dataField="description"
              editorType="dxTextArea"
              cssClass="description-control"
            >
              <dxo-label
                [text]="'COMMON.FIELDS.DESCRIPTION' | translate"
              ></dxo-label>
            </dxi-item>
          </dx-form>
        </app-panel>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-panel [title]="'COMMON.FIELDS.LEGAL-FOOTER' | translate">
          <dx-form
            labelLocation="top"
            [showColonAfterLabel]="false"
            [formData]="organisationUnitForm"
          >
            <dxi-item
              dataField="reportFooter"
              label=""
              editorType="dxHtmlEditor"
              [editorOptions]="{
                label: '',
                height: 350,
                toolbar: {
                  items: [
                    'undo',
                    'redo',
                    'separator',
                    {
                      name: 'size',
                      acceptedValues: ['8pt', '10pt', '12pt', '14pt', '18pt']
                    },
                    { name: 'font', acceptedValues: ['Arial'] },
                    'separator',
                    'bold',
                    'italic',
                    'strike',
                    'underline',
                    'separator',
                    'alignLeft',
                    'alignCenter',
                    'alignRight',
                    'alignJustify',
                    'separator',
                    'orderedList',
                    'bulletList',
                    'separator',
                    { name: 'header', acceptedValues: [false, 1, 2, 3, 4, 5] },
                    'separator',
                    'background',
                    'separator',
                    'link'
                  ]
                }
              }"
            >
              <dxo-label text=" "></dxo-label>
            </dxi-item>
          </dx-form>
        </app-panel>
      </div>
    </div>
  </app-page>
</div>
