<div>
  <app-page>
    <div class="top-cta">
      <dx-button
        [text]="'COMMON.BACK' | translate"
        icon="back"
        type="default"
        class="integration-btn"
        (click)="navigateBack()"
      >
      </dx-button>
      <dx-button
        class="cta-btn"
        type="normal"
        text="{{ 'COMMON.SAVE' | translate }}"
        (click)="submit()"
      ></dx-button>
    </div>
    <app-page-title
      [title]="
        editingMode
          ? ('ADMINISTRATION.CATEGORY-TITLE-EDIT' | translate)
          : ('ADMINISTRATION.CATEGORY-TITLE-ADD' | translate)
      "
    ></app-page-title>
    <app-panel [title]="'ADMINISTRATION.TITLE-DESCRIPTION' | translate">
      <dx-form #form labelMode="floating" [formData]="formData">
        <dxi-item dataField="name">
          <dxo-label [text]="'COMMON.FIELDS.NAME' | translate"></dxo-label>
          <dxi-validation-rule
            type="required"
            [message]="'VALIDATIONS.NAME-REQUIRED' | translate"
          ></dxi-validation-rule>
        </dxi-item>
        <dxi-item
          dataField="description"
          editorType="dxTextArea"
          [editorOptions]="{
            height: 230
          }"
        >
          <dxo-label
            [text]="'COMMON.FIELDS.DESCRIPTION' | translate"
          ></dxo-label>
        </dxi-item>
      </dx-form>
    </app-panel>
  </app-page>
</div>
