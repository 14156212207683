import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DxFormComponent } from 'devextreme-angular';
import { ToastrService } from 'ngx-toastr';
import { filter, map, of, switchMap, tap } from 'rxjs';
import { TrainingCategoryCreationAndUpdateModel } from 'src/app/models/training-category-creation-and-update.model';
import { TrainingCategoryModel } from 'src/app/models/training-category.model';
import { LoadingService } from 'src/app/services/loading.service';
import { TrainingCategoryService } from 'src/app/services/training-category.service';

@Component({
  selector: 'app-training-categories-details',
  templateUrl: './training-categories-details.component.html',
  styleUrl: './training-categories-details.component.scss'
})
export class TrainingCategoriesDetailsComponent implements OnInit {
  formData = {
    name: '',
    description: ''
  };
  currentTrainingCategory: TrainingCategoryModel | null = null;
  editingMode = false;

  @ViewChild('form', { static: true }) form!: DxFormComponent;

  constructor(
    private activatedRoute: ActivatedRoute,
    private trainingCategoryService: TrainingCategoryService,
    private router: Router,
    private loadingService: LoadingService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(
        map(params => params['id']),
        filter(id => !!id),
        tap(id => {
          this.editingMode = id !== 'new';
        }),
        switchMap(id =>
          this.loadingService.load(
            this.editingMode
              ? this.trainingCategoryService.getTrainingCategory(id)
              : of(null)
          )
        )
      )
      .subscribe(result => {
        this.currentTrainingCategory = result;
        if (!result) return;

        this.formData.name = result.name;
        this.formData.description = result.description || '';
      });
  }

  navigateBack(): void {
    this.router.navigate(['administration']);
  }

  submit(): void {
    const formValid = this.form.instance.validate().isValid;
    if (!formValid) {
      return;
    }
    const model: TrainingCategoryCreationAndUpdateModel = {
      name: this.formData.name,
      description: this.formData.description
    };

    if (this.editingMode && this.currentTrainingCategory) {
      const obs = this.trainingCategoryService.updateTrainingCategory(
        this.currentTrainingCategory.id,
        model
      );
      this.loadingService.load(obs).subscribe(() => {
        this.toastrService.success('Training category saved');
        this.navigateBack();
      });
    } else {
      const obs = this.trainingCategoryService.addTrainingCategory(model);
      this.loadingService.load(obs).subscribe(() => {
        this.toastrService.success('Training category added');
        this.navigateBack();
      });
    }
  }
}
