<div class="training-form">
  <app-page>
    <div class="top-cta">
      <dx-button
        [text]="'COMMON.BACK' | translate"
        icon="back"
        type="default"
        class="integration-btn"
        (click)="navigateBack()"
      >
      </dx-button>
      <dx-button
        *ngIf="!isViewMode"
        text="{{ 'COMMON.SAVE' | translate }}"
        type="normal"
        (click)="onFormSubmit()"
      >
      </dx-button>
      <ng-container *ngIf="primaryButton && isViewMode">
        <dx-drop-down-button
          [splitButton]="true"
          [text]="primaryButtonText"
          stylingMode="contained"
          type="normal"
          [dropDownOptions]="{ width: 230 }"
          [items]="menuActions"
          (onButtonClick)="onPrimaryButtonClick()"
          (onItemClick)="onMenuItemClick($event)"
          displayExpr="name"
          keyExpr="id"
        ></dx-drop-down-button>
      </ng-container>
    </div>
    <app-page-title
      [title]="
        isViewMode
          ? ('TRAINING.TITLE-VIEW' | translate)
          : editingTrainingId
          ? ('TRAINING.TITLE-EDIT' | translate)
          : ('TRAINING.TITLE-ADD' | translate)
      "
    ></app-page-title>
    <div class="row">
      <div class="col-md-12 col-lg-5">
        <app-panel class="avatar-panel">
          <div id="avatar-upload" class="uploader-container">
            <img
              class="user-avatar"
              [src]="trainingForm.imageBase64"
              *ngIf="trainingForm.imageBase64"
            />
            <img
              class="user-avatar"
              src=""
              appTrainingImageFallback
              *ngIf="!trainingForm.imageBase64 && editingTrainingId"
            />
            <div
              class="uploader-preview-container"
              *ngIf="!trainingForm.imageBase64 && !editingTrainingId"
            >
              <img src="assets/img/upload.jpg" />
              <span class="preview-title">{{
                "COMMON.UPLOAD-IMAGE" | translate
              }}</span>
              <span class="preview-description">{{
                "COMMON.UPLOAD-IMAGE-SIZE" | translate
              }}</span>
            </div>
          </div>
          <dx-file-uploader
            #fileUploader
            dialogTrigger="#avatar-upload"
            dropZone="#avatar-upload"
            [multiple]="false"
            [allowedFileExtensions]="['.jpg', '.jpeg', '.gif', '.png']"
            uploadMode="useForm"
            [visible]="false"
            (valueChange)="onAvatarUploaded($event)"
            [readOnly]="isViewMode"
          ></dx-file-uploader>
        </app-panel>
      </div>
      <div class="col-md-12 col-lg-7">
        <app-panel
          class="training-panel"
          [title]="
            isViewMode ? ('TRAINING.FORM.TITLE-DESCRIPTION' | translate) : null
          "
        >
          <dx-form
            #descriptionForm
            labelMode="floating"
            [showColonAfterLabel]="false"
            [formData]="trainingForm"
            [readOnly]="isViewMode"
          >
            <dxi-item
              *ngIf="!isViewMode && !editingTrainingId"
              dataField="templateId"
            >
              <div *dxTemplate>
                <dx-drop-down-box
                  #templateDropDown
                  [(value)]="trainingForm.templateId"
                  [inputAttr]="{ 'aria-label': 'Owner' }"
                  valueExpr="id"
                  displayExpr="name"
                  [label]="'COMMON.FIELDS.TRAINING-TEMPLATE' | translate"
                  [dataSource]="templates"
                  labelMode="floating"
                  stylingMode="outlined"
                  (onValueChanged)="templateDropDown.instance.close()"
                >
                  <div *dxTemplate="let data of 'content'">
                    <dx-tree-view
                      [searchEnabled]="true"
                      [dataSource]="templates"
                      keyExpr="id"
                      [selectNodesRecursive]="false"
                      displayExpr="name"
                      (onItemClick)="onTrainingTemplateSelected($event)"
                    >
                    </dx-tree-view>
                  </div>
                </dx-drop-down-box>
              </div>
            </dxi-item>
            <dxi-item dataField="name">
              <dxo-label [text]="'COMMON.FIELDS.TITLE' | translate"></dxo-label>
              <dxi-validation-rule
                type="required"
                [message]="'VALIDATIONS.TITLE-REQUIRED' | translate"
              ></dxi-validation-rule>
            </dxi-item>
            <dxi-item
              dataField="description"
              editorType="dxTextArea"
              [editorOptions]="{
                height: 100
              }"
            >
              <dxo-label
                [text]="'COMMON.FIELDS.DESCRIPTION' | translate"
              ></dxo-label>
            </dxi-item>
          </dx-form>
        </app-panel>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-panel [title]="'TRAINING.FORM.DATE-TIME' | translate">
          <div *ngIf="!isViewMode">
            <app-training-date-selection
              #trainingDateSelection
              [onlyFutureDatesAllowed]="!editingTrainingId"
              (onFutureDateCheck)="onFutureDateCheck($event)"
            ></app-training-date-selection>
          </div>
          <div *ngIf="isViewMode && training">
            <div
              *ngFor="let date of training!.dates"
              class="training-date-block"
            >
              <div>
                <div
                  *ngIf="areDatesOnSameDay(date.start, date.end)"
                  class="training-date-block-date"
                >
                  {{
                    date.start
                      | date
                        : "EEEE, dd LLLL yyyy"
                        : ""
                        : translateService.currentLang
                  }}
                </div>
                <div
                  *ngIf="!areDatesOnSameDay(date.start, date.end)"
                  class="training-date-block-date"
                >
                  {{
                    date.start
                      | date
                        : "EEEE, dd LLLL yyyy"
                        : ""
                        : translateService.currentLang
                  }}
                  -
                  {{
                    date.end
                      | date
                        : "EEEE, dd LLLL yyyy"
                        : ""
                        : translateService.currentLang
                  }}
                </div>
                <span
                  *ngIf="translateService.currentLang == 'en'"
                  class="training-date-block-time"
                >
                  {{ date.start | date : "hh:mm a" }} -
                  {{ date.end | date : "hh:mm a" }}
                </span>
                <span
                  *ngIf="translateService.currentLang == 'de'"
                  class="training-date-block-time"
                >
                  {{ date.start | date : "HH:mm" }} -
                  {{ date.end | date : "HH:mm" }}
                </span>
              </div>
              <div class="training-date-block-employee">
                <div
                  class="training-date-block-employee-stats"
                  (click)="showEmployeeModal(0)"
                >
                  <div class="training-date-block-employee-count">
                    {{ acceptedMembers.length }}
                  </div>
                  <div class="training-date-block-employee-status">
                    {{ "COMMON.FIELDS.EMPLOYEE-STATUSES.ACCEPTED" | translate }}
                  </div>
                </div>
                <div class="training-date-block-employee-separator"></div>
                <div
                  class="training-date-block-employee-stats"
                  (click)="showEmployeeModal(1)"
                >
                  <div class="training-date-block-employee-count">
                    {{ openMembers.length }}
                  </div>
                  <div class="training-date-block-employee-status">
                    {{ "COMMON.FIELDS.EMPLOYEE-STATUSES.OPEN" | translate }}
                  </div>
                </div>
                <div class="training-date-block-employee-separator"></div>
                <div
                  class="training-date-block-employee-stats"
                  (click)="showEmployeeModal(2)"
                >
                  <div class="training-date-block-employee-count">
                    {{ declinedMembers.length }}
                  </div>
                  <div class="training-date-block-employee-status">
                    {{ "COMMON.FIELDS.EMPLOYEE-STATUSES.DECLINED" | translate }}
                  </div>
                </div>
                <div
                  class="training-date-block-employee-separator"
                  *ngIf="requestedMembers.length > 0"
                ></div>
                <div
                  *ngIf="requestedMembers.length > 0"
                  class="training-date-block-employee-stats-danger"
                  (click)="showEmployeeModal(3)"
                >
                  <div class="training-date-block-employee-count">
                    {{ requestedMembers.length }}
                  </div>
                  <div class="training-date-block-employee-status">
                    {{
                      "COMMON.FIELDS.EMPLOYEE-STATUSES.REQUESTED" | translate
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </app-panel>
      </div>
    </div>
    <div>
      <app-panel [title]="'TRAINING.FORM.EXTENDED-DESCRIPTION' | translate">
        <div class="training-description-wrapper">
          <div
            class="training-description-expand"
            (click)="toggleDescriptionExpanded()"
          >
            {{ isDescriptionExpanded ? "Collapse ↑" : "Expand ↓" }}
          </div>
          <dx-form
            labelMode="floating"
            [showColonAfterLabel]="false"
            [formData]="trainingForm"
            [readOnly]="isViewMode"
          >
            <dxi-item>
              <dxo-label [visible]="false"></dxo-label>
              <div *dxTemplate>
                <dx-html-editor
                  [height]="isDescriptionExpanded ? 'auto' : '120px'"
                  [(value)]="trainingForm.descriptionExtended"
                  [readOnly]="isViewMode"
                >
                  <dxo-toolbar *ngIf="!isViewMode" [multiline]="true">
                    <dxi-item name="undo"></dxi-item>
                    <dxi-item name="redo"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item
                      name="size"
                      [acceptedValues]="[
                        '8pt',
                        '10pt',
                        '12pt',
                        '14pt',
                        '18pt',
                        '24pt',
                        '36pt'
                      ]"
                    ></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="bold"></dxi-item>
                    <dxi-item name="italic"></dxi-item>
                    <dxi-item name="strike"></dxi-item>
                    <dxi-item name="underline"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="alignLeft"></dxi-item>
                    <dxi-item name="alignCenter"></dxi-item>
                    <dxi-item name="alignRight"></dxi-item>
                    <dxi-item name="alignJustify"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="orderedList"></dxi-item>
                    <dxi-item name="bulletList"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item
                      name="header"
                      [acceptedValues]="[false, 1, 2, 3, 4, 5]"
                    ></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="color"></dxi-item>
                    <dxi-item name="background"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="link"></dxi-item>
                  </dxo-toolbar>
                </dx-html-editor>
              </div>
            </dxi-item>
          </dx-form>
        </div>
      </app-panel>
    </div>
    <div>
      <app-panel [title]="'TRAINING.FORM.TRAINING-SETTINGS' | translate">
        <dx-form
          #settingsForm
          labelMode="floating"
          [showColonAfterLabel]="false"
          [formData]="trainingForm"
          [readOnly]="isViewMode"
        >
          <dxi-item itemType="group">
            <dxo-col-count-by-screen
              [xs]="1"
              [sm]="1"
              [md]="1"
              [lg]="2"
            ></dxo-col-count-by-screen>
            <dxi-item
              itemType="group"
              [caption]="
                !isViewMode
                  ? ('TRAINING.FORM.GENERAL-SETTINGS' | translate)
                  : null
              "
            >
              <dxi-item>
                <dxo-label
                  [text]="'COMMON.FIELDS.TRAINERS' | translate"
                ></dxo-label>
                <div *dxTemplate>
                  <dx-drop-down-box
                    [(value)]="trainingForm.trainerIds"
                    valueExpr="id"
                    displayExpr="name"
                    [label]="'COMMON.FIELDS.TRAINERS' | translate"
                    [dataSource]="trainers"
                    [readOnly]="isViewMode"
                    labelMode="floating"
                    stylingMode="outlined"
                  >
                    <div *dxTemplate="let data of 'content'">
                      <dx-tree-view
                        [searchEnabled]="true"
                        [dataSource]="trainers"
                        dataStructure="plain"
                        keyExpr="id"
                        selectionMode="multiple"
                        showCheckBoxesMode="normal"
                        [selectNodesRecursive]="false"
                        displayExpr="name"
                        [selectByClick]="true"
                        (onItemSelectionChanged)="onTrainerSelected($event)"
                      >
                      </dx-tree-view>
                    </div>
                  </dx-drop-down-box>
                </div>
              </dxi-item>
              <dxi-item
                [editorOptions]="trainingTypeOptions"
                dataField="trainingType"
                editorType="dxSelectBox"
                [editorOptions]="{ showClearButton: true }"
              >
                <dxi-validation-rule
                  type="required"
                  [message]="'VALIDATIONS.TRAINING-TYPE-REQUIRED' | translate"
                ></dxi-validation-rule>
                <dxo-label
                  [text]="'COMMON.FIELDS.TRAINING-TYPE' | translate"
                ></dxo-label>
              </dxi-item>
              <dxi-item *ngIf="trainingForm.trainingType === 0">
                <dxo-label text="Training time"></dxo-label>
                <div *dxTemplate>
                  <div>
                    <dx-validator
                      [adapter]="trainingTimeAdapter"
                      validationGroup="trainingTimeGroup"
                    >
                      <dxi-validation-rule
                        type="custom"
                        [validationCallback]="validateTrainingTime"
                        [message]="
                          'VALIDATIONS.TRAINING-TIME-MINUTE' | translate
                        "
                      ></dxi-validation-rule>
                      <dx-validation-summary></dx-validation-summary>
                    </dx-validator>
                    <div #timePicker>
                      <app-time-picker
                        [isInvalid]="!isTrainingTimeValid"
                        [disabled]="isViewMode"
                        (timeChanged)="handleTimePicker($event)"
                        [defaultValue]="{
                          hours: trainingForm.iddHours!,
                          minutes: trainingForm.iddMinutes!
                        }"
                      ></app-time-picker>
                    </div>
                  </div>
                </div>
              </dxi-item>
              <dxi-item dataField="maximumMembers" editorType="dxNumberBox">
                <dxo-label
                  [text]="'COMMON.FIELDS.MAXIMUM-PARTICIPANTS' | translate"
                ></dxo-label>
              </dxi-item>
              <dxi-item
                *ngIf="trainingForm.trainingType === 0 && isWbdConfigured"
                [editorOptions]="learningTypeOptions"
                dataField="lernart"
                editorType="dxSelectBox"
              >
                <dxo-label
                  [text]="'COMMON.FIELDS.LEARNING-TYPE' | translate"
                ></dxo-label>
              </dxi-item>
              <dxi-item
                *ngIf="trainingForm.trainingType === 0 && isWbdConfigured"
                [editorOptions]="learningContentOptions"
                dataField="lerninhalt"
                editorType="dxSelectBox"
              >
                <dxo-label
                  [text]="'COMMON.FIELDS.LEARNING-CONTENT' | translate"
                ></dxo-label>
              </dxi-item>
            </dxi-item>
            <dxi-item
              itemType="group"
              [caption]="
                !isViewMode
                  ? ('TRAINING.FORM.APPLICABILITY-VISIBILITY' | translate)
                  : null
              "
            >
              <dxi-item>
                <dxo-label
                  [text]="'COMMON.FIELDS.LOCATIONS-DEPARTMENTS' | translate"
                ></dxo-label>
                <div *dxTemplate>
                  <dx-drop-down-box
                    [(value)]="selectedOrganizationUnitsIds"
                    valueExpr="id"
                    displayExpr="name"
                    [label]="'COMMON.FIELDS.LOCATIONS-DEPARTMENTS' | translate"
                    [dataSource]="organizationUnits"
                    [readOnly]="isViewMode"
                    labelMode="floating"
                    stylingMode="outlined"
                  >
                    <div *dxTemplate="let data of 'content'">
                      <dx-tree-view
                        [searchEnabled]="true"
                        [dataSource]="organizationUnits"
                        dataStructure="plain"
                        keyExpr="id"
                        selectionMode="multiple"
                        showCheckBoxesMode="normal"
                        [selectNodesRecursive]="true"
                        displayExpr="name"
                        [selectByClick]="true"
                        (onItemSelectionChanged)="onOrganizationUnitsSelected($event)"
                      >
                      </dx-tree-view>
                    </div>
                  </dx-drop-down-box>
                </div>
              </dxi-item>
              <!-- <dxi-item>
                <dxo-label
                  [text]="'COMMON.FIELDS.LOCATIONS' | translate"
                ></dxo-label>
                <div *dxTemplate>
                  <dx-drop-down-box
                    [(value)]="trainingForm.organisationUnitLocationIds"
                    valueExpr="id"
                    displayExpr="name"
                    [label]="'COMMON.FIELDS.LOCATIONS' | translate"
                    [dataSource]="locations"
                    [readOnly]="isViewMode"
                    labelMode="floating"
                    stylingMode="outlined"
                  >
                    <div *dxTemplate="let data of 'content'">
                      <dx-tree-view
                        [searchEnabled]="true"
                        [dataSource]="locations"
                        dataStructure="plain"
                        keyExpr="id"
                        selectionMode="multiple"
                        showCheckBoxesMode="normal"
                        [selectNodesRecursive]="false"
                        displayExpr="name"
                        [selectByClick]="true"
                        (onItemSelectionChanged)="onLocationSelected($event)"
                      >
                      </dx-tree-view>
                    </div>
                  </dx-drop-down-box>
                </div>
              </dxi-item>
              <dxi-item>
                <dxo-label
                  [text]="'COMMON.FIELDS.DEPARTMENTS' | translate"
                ></dxo-label>
                <div *dxTemplate>
                  <dx-drop-down-box
                    [(value)]="trainingForm.organisationUnitDepartmentIds"
                    valueExpr="id"
                    displayExpr="name"
                    [label]="'COMMON.FIELDS.DEPARTMENTS' | translate"
                    [dataSource]="departments"
                    [readOnly]="isViewMode"
                    labelMode="floating"
                    stylingMode="outlined"
                  >
                    <div *dxTemplate="let data of 'content'">
                      <dx-tree-view
                        [searchEnabled]="true"
                        [dataSource]="departments"
                        dataStructure="plain"
                        keyExpr="id"
                        selectionMode="multiple"
                        showCheckBoxesMode="normal"
                        [selectNodesRecursive]="false"
                        displayExpr="name"
                        [selectByClick]="true"
                        (onItemSelectionChanged)="onDepartmentSelected($event)"
                      >
                      </dx-tree-view>
                    </div>
                  </dx-drop-down-box>
                </div>
              </dxi-item> -->
              <dxi-item>
                <dxo-label
                  [text]="'COMMON.FIELDS.TRAINING-GROUPS' | translate"
                ></dxo-label>
                <div *dxTemplate>
                  <dx-drop-down-box
                    [(value)]="trainingForm.trainingGroupIds"
                    [inputAttr]="{ 'aria-label': 'Owner' }"
                    valueExpr="id"
                    displayExpr="name"
                    [label]="'COMMON.FIELDS.TRAINING-GROUPS' | translate"
                    [dataSource]="trainingGroups"
                    [readOnly]="isViewMode"
                    labelMode="floating"
                    stylingMode="outlined"
                  >
                    <div *dxTemplate="let data of 'content'">
                      <dx-tree-view
                        [searchEnabled]="true"
                        [dataSource]="trainingGroups"
                        dataStructure="plain"
                        keyExpr="id"
                        selectionMode="multiple"
                        showCheckBoxesMode="normal"
                        [selectNodesRecursive]="false"
                        displayExpr="name"
                        [selectByClick]="true"
                        (onItemSelectionChanged)="
                          onTrainingGroupSelected($event)
                        "
                      >
                      </dx-tree-view>
                    </div>
                  </dx-drop-down-box>
                </div>
              </dxi-item>
              <dxi-item>
                <dxo-label
                  [text]="'COMMON.FIELDS.TRAINING-CATEGORIES' | translate"
                ></dxo-label>
                <div *dxTemplate>
                  <dx-drop-down-box
                    [(value)]="trainingForm.trainingCategoryIds"
                    [inputAttr]="{ 'aria-label': 'Owner' }"
                    valueExpr="id"
                    displayExpr="name"
                    [label]="'COMMON.FIELDS.TRAINING-CATEGORIES' | translate"
                    [dataSource]="trainingCategories"
                    [readOnly]="isViewMode"
                    labelMode="floating"
                    stylingMode="outlined"
                  >
                    <div *dxTemplate="let data of 'content'">
                      <dx-tree-view
                        [searchEnabled]="true"
                        [dataSource]="trainingCategories"
                        dataStructure="plain"
                        keyExpr="id"
                        selectionMode="multiple"
                        showCheckBoxesMode="normal"
                        [selectNodesRecursive]="false"
                        displayExpr="name"
                        [selectByClick]="true"
                        (onItemSelectionChanged)="
                          onTrainingCategorySelected($event)
                        "
                      >
                      </dx-tree-view>
                    </div>
                  </dx-drop-down-box>
                </div>
              </dxi-item>
            </dxi-item>
          </dxi-item>
        </dx-form>
        <div class="row checkboxes-row">
          <div class="col-12">
            <dx-form
              [colCountByScreen]="{ lg: 2, md: 1 }"
              [formData]="trainingForm"
              [showColonAfterLabel]="false"
              labelMode="static"
              [readOnly]="isViewMode"
            >
              <dxi-item
                dataField="sendCertificateLink"
                editorType="dxCheckBox"
                [editorOptions]="{
                text: 'COMMON.FIELDS.CERTIFICATE-LINK-IN-COMPLETION-MAIL' | translate,
              }"
              >
                <dxo-label [visible]="false"> </dxo-label>
              </dxi-item>
              <dxi-item
                dataField="sendFeedbackLink"
                editorType="dxCheckBox"
                [editorOptions]="{
                text: 'COMMON.FIELDS.FEEDBACK-LINK-IN-COMPLETION-MAIL' | translate,
              }"
              >
                <dxo-label [visible]="false"> </dxo-label>
              </dxi-item>
            </dx-form>
          </div>
        </div>
      </app-panel>
    </div>
    <div>
      <app-panel
        class="training-location-panel"
        [title]="'COMMON.FIELDS.TRAINING-LOCATION' | translate"
      >
        <div class="row">
          <div class="col-md-12 col-lg-6">
            <dx-form
              #locationForm
              [formData]="trainingForm"
              [showColonAfterLabel]="false"
              labelMode="outside"
              labelMode="floating"
              [readOnly]="
                isViewMode ||
                (!!editingTrainingId &&
                  trainingForm.trainingLocation.locationType !== 0 &&
                  trainingForm.trainingLocation.locationType !== 1)
              "
            >
              <dxi-item
                [editorOptions]="trainingLocationTypeOptions"
                dataField="trainingLocation.locationType"
                editorType="dxSelectBox"
              >
                <dxi-validation-rule
                  type="required"
                  [message]="'VALIDATIONS.EVENT-TYPE-REQUIRED' | translate"
                ></dxi-validation-rule>
                <dxo-label
                  [text]="'COMMON.FIELDS.EVENT-TYPE' | translate"
                ></dxo-label>
              </dxi-item>
              <dxi-item
                *ngIf="trainingForm.trainingLocation.locationType === 0"
              >
                <div *dxTemplate>
                  <dx-validator
                    [adapter]="trainingLocationAdapter"
                    validationGroup="trainingLocationGroup"
                  >
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                    <dx-validation-summary></dx-validation-summary>
                  </dx-validator>
                  <div #googleAutocomplete>
                    <dx-autocomplete
                      [(text)]="googleSearchText"
                      [searchExpr]="autocompleteSearchFilter"
                      [label]="'COMMON.FIELDS.TRAINING-LOCATION' | translate"
                      [label]="
                        'TRAINING.FORM.SEARCH-TRAINING-LOCATION' | translate
                      "
                      [dataSource]="googlePlaces"
                      valueExpr="description"
                      (onValueChanged)="getGooglePlaces($event)"
                      (onItemClick)="setLocationPlace($event)"
                      [readOnly]="isViewMode"
                      labelMode="floating"
                      stylingMode="outlined"
                      [validationErrors]="[
                        {
                          message:
                            'VALIDATIONS.TRAINING-LOCATION-REQUIRED' | translate
                        }
                      ]"
                      [validationStatus]="locationValidationStatus"
                    >
                    </dx-autocomplete>
                  </div>
                </div>
              </dxi-item>
              <dxi-item
                *ngIf="trainingForm.trainingLocation.locationType === 1"
                dataField="trainingLocation.location"
              >
                <dxo-label [text]="getTrainingLocationLabel"></dxo-label>
              </dxi-item>
              <dxi-item
                dataField="trainingLocation.suffix"
                editorType="dxTextArea"
                [editorOptions]="{
                  height: 100
                }"
              >
                <dxo-label [text]="getTrainingLocationDetailsLabel"></dxo-label>
              </dxi-item>
            </dx-form>
          </div>
          <div class="col-md-12 col-lg-6">
            <div
              *ngIf="
                trainingForm.trainingLocation.locationType ==
                TrainingLocationType.Address
              "
            >
              <ng-container
                *ngIf="
                  trainingForm.trainingLocation.latitude &&
                  trainingForm.trainingLocation.longitude
                "
              >
                <app-google-maps
                  class="my-3"
                  [location]="trainingForm.trainingLocation"
                ></app-google-maps>
              </ng-container>
            </div>
            <div
              *ngIf="
                trainingForm.trainingLocation.locationType ==
                TrainingLocationType.GoToWebinar
              "
              class="training-location-image-container"
            >
              <img
                class="training-location-image"
                src="assets/img/gotowebinar-logo.jpg"
              />
            </div>
            <div
              *ngIf="
                trainingForm.trainingLocation.locationType ==
                TrainingLocationType.Webex
              "
              class="training-location-image-container"
            >
              <img
                class="training-location-image"
                src="assets/img/webex-logo.jpg"
              />
            </div>
          </div>
        </div>
      </app-panel>
    </div>
  </app-page>
</div>

<app-training-employee-popup
  #trainingEmployeeModal
  [trainingId]="editingTrainingId"
  [isPopupVisible]="isEmployeePopupVisible"
  [tab]="employeePopupTab"
  [openMembers]="openMembers"
  [acceptedMembers]="acceptedMembers"
  [declinedMembers]="declinedMembers"
  [requestedMembers]="requestedMembers"
  (onPopupClose)="onEmployeePopupClose()"
  (onReloadMembers)="onLoadMembers()"
/>

<app-confirmation-dialog
  [(showModal)]="showDeleteTrainingModal"
  (submit)="onDeleteTrainingClick()"
  [options]="modalConfirmationOptions"
></app-confirmation-dialog>

<app-training-cancel-dialog
  [(showModal)]="showCancelTrainingModal"
  (submit)="onCancelTrainingClick($event)"
></app-training-cancel-dialog>
