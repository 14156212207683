import {Component, ViewChild} from '@angular/core';
import {PersonService} from '../../../services/person.service';
import {PersonSummaryModel} from '../../../models/person/person-summary.model';
import {TableColumn} from '../../../models/table-column.model';
import {ActivatedRoute, Router} from '@angular/router';
import {LoadingService} from '../../../services/loading.service';
import {DxTabsTypes} from 'devextreme-angular/ui/tabs';
import {ToastrService} from 'ngx-toastr';
import {FileUtilService} from '../../../services/file-util.service';
import {RawFileModel} from 'src/app/models/raw-file-model';
import {DxFileUploaderComponent} from 'devextreme-angular';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin} from 'rxjs';
import {PersonSummaryWithIddModel} from "../../../models/person/person-summary-with-idd.model";
import {TimeService} from "../../../services/time.service";

@Component({
    selector: 'app-employee-overview',
    templateUrl: './employee-overview.component.html',
    styleUrls: ['./employee-overview.component.scss'],
})
export class EmployeeOverviewComponent {
    private certificatesUploader!: DxFileUploaderComponent;

    @ViewChild('certificatesUploader') set content(
        content: DxFileUploaderComponent
    ) {
        if (content) {
            this.certificatesUploader = content;
        }
    }

    tabs: any[] = [
        {
            id: 0,
            text: this.translateService.instant('EMPLOYEE.OVERVIEW.EMPLOYEES-TAB'),
        },
        {
            id: 1,
            text: this.translateService.instant(
                'EMPLOYEE.OVERVIEW.INACTIVE-EMPLOYEES-TAB'
            ),
        },
        {
            id: 2,
            text: this.translateService.instant(
                'EMPLOYEE.OVERVIEW.IMPORT-CERTIFICATES-TAB'
            ),
        },
    ];
    selectedTab: number = 0;
    selectedPersons: string[] = [];
    persons: PersonSummaryWithIddModel[] = [];
    personsColumns: TableColumn[] = [
        {
            caption: this.translateService.instant('COMMON.FIELDS.LAST-NAME'),
            dataField: 'lastName',
            cssClass: 'width-200',
        },
        {
            caption: this.translateService.instant('COMMON.FIELDS.FIRST-NAME'),
            dataField: 'firstName',
            cssClass: 'width-200',
        },
        {
            caption: this.translateService.instant('COMMON.FIELDS.EMAIL'),
            dataField: 'email',
            cssClass: 'width-150',
        },
        {
            caption: 'IDD',
            dataField: 'iddTimeMinutes',
            cssClass: 'width-150',
            calculateDisplayValue: (data: PersonSummaryWithIddModel): string => {
                return TimeService.getHoursAndMinutesDisplayStringFromIddTimeMinutes(data.iddTimeMinutes)
            },
            calculateSortValue: (data: any) => {
                return data.iddTimeMinutes;
            },
            headerFilter: (data: any) => {
                data.dataSource.postProcess = (results: any) => {
                    const iddTimeMinutes = results.map((result: any) => result.value);
                    results.splice(0, results.length);
                    for (let iddTime of iddTimeMinutes) {
                        results.push({
                            text: TimeService.getHoursAndMinutesDisplayStringFromIddTimeMinutes(iddTime),
                            value: iddTime
                        })
                    }
                }
            }
        },
        {
            caption: this.translateService.instant('COMMON.FIELDS.ROLE'),
            dataField: 'role.name',
        },
        {
            caption: this.translateService.instant('COMMON.FIELDS.LOCATION'),
            dataField: 'location.name',
            cssClass: 'width-150',
        },
        {
            caption: this.translateService.instant('COMMON.FIELDS.TRAINING-GROUPS'),
            dataField: 'trainingGroups',
            cssClass: 'width-350',
            headerFilter: (data: any) => {
                data.dataSource.postProcess = (results: any) => {
                    const trainingGroupNames = results.reduce(
                        (a: any, b: any) => a.concat(b.value),
                        []
                    );
                    const parsedTrainingGroupNames: string[] = [];
                    trainingGroupNames.forEach((group: string) => {
                        if (!group || !group.includes(', ')) {
                            parsedTrainingGroupNames.push(group);
                        } else if (group.includes(', ')) {
                            const groups = group.split(', ');
                            parsedTrainingGroupNames.push(...groups);
                        }
                    });
                    const uniqueTrainingGroupNames = [
                        ...new Set(parsedTrainingGroupNames),
                    ];

                    results.splice(0, results.length);
                    uniqueTrainingGroupNames.forEach((tgName) => {
                        if (tgName) {
                            results.push({
                                text: tgName,
                                value: tgName,
                            });
                        } else {
                            results.push({
                                text: '(Blanks)',
                                value: null,
                            });
                        }
                    });
                    return results;
                };
            },
            filterExpression: (value: any) => {
                function getTrainingGroupsFilterString(rowData: PersonSummaryModel) {
                    const groups = rowData.trainingGroups.map((tg) => tg.name);
                    return !!groups.join(',') ? groups.join(',') + ',' : 'empty';
                }

                return [[getTrainingGroupsFilterString, 'contains', (value + ',') || 'empty']];
            },
            cellValue: (data: PersonSummaryModel | any): string => {
                return data.trainingGroups.map((tg: any) => tg.name).join(', ');
            },
        }
    ];
    inactive: boolean = false;
    certificate!: File | null;
    personsFilterIds: string[] | null = null;
    personsTrainingGroupFilterId: string | null = null;

    constructor(
        private personService: PersonService,
        private router: Router,
        private loadingService: LoadingService,
        private toastrService: ToastrService,
        private fileUtilService: FileUtilService,
        private translateService: TranslateService,
        private route: ActivatedRoute
    ) {
        const personsFilter = this.route.snapshot.queryParamMap.get('persons');
        const personsTrainingGroupFilter = this.route.snapshot.queryParamMap.get('trainingGroupId');
        if (personsFilter) {
            this.personsFilterIds = JSON.parse(personsFilter);
        }
        if (personsTrainingGroupFilter) {
            this.personsTrainingGroupFilterId = personsTrainingGroupFilter;
        }

        this.loadEmployees(false);
    }

    ngOnInit(): void {
    }

    onTabChange(e: DxTabsTypes.ItemClickEvent) {
        this.selectedPersons = [];
        this.selectedTab = e.itemData.id;

        if (this.selectedTab == 0) {
            this.loadEmployees(false);
        } else if (this.selectedTab == 1) {
            this.loadEmployees(true);
        }
    }

    loadEmployees(inactiveOnly: boolean = false) {
        this.loadingService
            .load(this.personService.getPersonsByState(inactiveOnly))
            .subscribe((persons) => {
                this.persons = persons;

                if (this.personsFilterIds) {
                    this.persons = this.persons.filter(person => {
                        return this.personsFilterIds!.includes(person.id);
                    })
                }
                if (this.personsTrainingGroupFilterId) {
                    this.persons = this.persons.filter(person => {
                        return person.trainingGroups.some(group => group.id === this.personsTrainingGroupFilterId);
                    });
                }
            });
    }

    onAddClick(): void {
        this.router.navigate(['/employee/add']);
    }

    onCertificateSelected(e: any) {
        if (
            e.value &&
            e.value[0] &&
            (e.value[0].name.endsWith('.xls') || e.value[0].name.endsWith('.xlsx'))
        ) {
            this.certificate = e.value[0];
        } else if (e.value && e.value[0]) {
            this.toastrService.error(
                this.translateService.instant('VALIDATIONS.FILE-NOT-SUPPORTED')
            );
            this.certificate = null;
            this.certificatesUploader.instance.reset();
        }
    }

    importCertificate() {
        this.fileUtilService
            .convertFileToRawFileModel(this.certificate as File)
            .then((data: RawFileModel) => {
                const rawFile: RawFileModel = {
                    name: data.name,
                    data: data.dataBase64,
                };

                this.loadingService
                    .load(this.personService.importCertificates(rawFile))
                    .subscribe({
                        next: () => {
                            this.toastrService.success(
                                this.translateService.instant(
                                    'EMPLOYEE.OVERVIEW.IMPORT-SUCCESS'
                                )
                            );
                            this.certificate = null;
                            this.certificatesUploader.instance.reset();
                        },
                        error: () => {
                            this.toastrService.error(
                                this.translateService.instant('EMPLOYEE.OVERVIEW.IMPORT-ERROR')
                            );
                        },
                    });
            });
    }

    navigateToDetails(id: string) {
        this.router.navigate(['/employee/edit', id]);
    }

    changeUsersStatus(status: string) {
        const requests = this.selectedPersons.map((id: string) =>
            this.personService.togglePersonState(id, status)
        );
        this.loadingService.load(forkJoin(requests)).subscribe(() => {
            this.selectedPersons = [];
            this.loadEmployees(this.selectedTab == 0 ? false : true);
        });
    }

    onPersonsSelected(ids: string[]) {
        this.selectedPersons = ids;
    }
}
