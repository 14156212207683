import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Configuration} from "../../assets/Configuration";
import { PersonSummaryModel } from '../models/person/person-summary.model';
import { PersonCreationAndUpdateModel } from '../models/person/person-creation-and-update.model';
import { PersonFullModel } from '../models/person/person-full.model';
import { AddExternalCertificateModel } from '../models/person/add-external-certificate.model';
import { RawFileModel } from '../models/raw-file-model';
import {PersonSummaryWithIddModel} from "../models/person/person-summary-with-idd.model";

@Injectable({
  providedIn: 'root',
})
export class PersonService {
  constructor(private httpClient: HttpClient) {}

  public getAllPersons(): Observable<PersonSummaryModel[]> {
    return this.httpClient.get<PersonSummaryModel[]>(
      Configuration.getApiBaseUrl() + '/person/'
    );
  }

  public getPersonsByState(inactive: boolean): Observable<PersonSummaryWithIddModel[]> {
    return this.httpClient.get<PersonSummaryWithIddModel[]>(
      Configuration.getApiBaseUrl() + '/person/by-state?inactive=' + inactive
    );
  }

  public getPerson(id: string): Observable<PersonFullModel> {
    return this.httpClient.get<PersonFullModel>(
      Configuration.getApiBaseUrl() + '/person/' + id
    );
  }

  public addPerson(
    creationAndUpdateModel: PersonCreationAndUpdateModel
  ): Observable<PersonFullModel> {
    return this.httpClient.post<PersonFullModel>(
      Configuration.getApiBaseUrl() + '/person/',
      creationAndUpdateModel
    );
  }

  public updatePerson(
    editingPersonId: string,
    creationAndUpdateModel: PersonCreationAndUpdateModel
  ): Observable<PersonFullModel> {
    return this.httpClient.post<PersonFullModel>(
      Configuration.getApiBaseUrl() + '/person/' + editingPersonId,
      creationAndUpdateModel
    );
  }

  public addExternalCertificate(
    personId: string,
    model: AddExternalCertificateModel
  ): Observable<void> {
    return this.httpClient.post<void>(
      Configuration.getApiBaseUrl() + '/person/' + personId + '/external-certificate',
      model
    );
  }

  public togglePersonState(
    editingPersonId: string,
    state: string
  ): Observable<PersonFullModel> {
    return this.httpClient.post<PersonFullModel>(
      Configuration.getApiBaseUrl() + '/person/' + editingPersonId + '/' + state,
      null
    );
  }

  public importCertificates(
    file: RawFileModel
  ): Observable<PersonFullModel> {
    return this.httpClient.post<PersonFullModel>(
      Configuration.getApiBaseUrl() + '/completedevent/import-certificates',
      file
    );
  }

  public getPersonIddTimeMinutes(personId: string): Observable<number> {
    return this.httpClient.get<number>(Configuration.getApiBaseUrl() + '/person/' + personId + '/idd-time-minutes',);
  }
}
