import { Component } from '@angular/core';
import { LoadingService } from '../../../services/loading.service';
import { MyTrainingsService } from '../../../services/my-trainings.service';
import { TrainingTypeModel } from '../../../models/training/training-type.model';
import { DxTabsTypes } from 'devextreme-angular/ui/tabs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-my-trainings',
  templateUrl: './my-trainings.component.html',
  styleUrls: ['./my-trainings.component.scss'],
})
export class MyTrainingsComponent {
  tabs: any[] = [
    {
      id: 0,
      text: this.translateService.instant(
        'MY-TRAININGS.OVERVIEW.OPEN-TRAININGS'
      ),
    },
    {
      id: 1,
      text: this.translateService.instant(
        'MY-TRAININGS.OVERVIEW.COMPLETED-TRAININGS'
      ),
    },
  ];

  selectedTab: number = 0;
  completedTrainingsCoursesIds!: string[];
  trainingsCourses: any = [];
  myTrainings: any = [];
  filters: MyTrainingsFilters = {
    trainingTypes: [],
    trainingCategories: [],
    eventTypes: [],
    searchString: '',
  };

  trainingTypes: any[] = [
    {
      id: TrainingTypeModel.IDD,
      name: this.translateService.instant('COMMON.FIELDS.TRAINING-TYPES.IDD'),
    },
    {
      id: TrainingTypeModel.Compliance,
      name: this.translateService.instant(
        'COMMON.FIELDS.TRAINING-TYPES.COMPLIANCE'
      ),
    },
    {
      id: TrainingTypeModel.General,
      name: this.translateService.instant(
        'COMMON.FIELDS.TRAINING-TYPES.GENERAL'
      ),
    },
  ];

  trainingCategories!: any[];

  eventTypes: any[] = [
    {
      id: 0,
      name: this.translateService.instant(
        'COMMON.FIELDS.EVENT-TYPES.IN-PERSON'
      ),
    },
    {
      id: 1,
      name: this.translateService.instant('COMMON.FIELDS.EVENT-TYPES.WEBINAR'),
    },
    {
      id: 2,
      name: this.translateService.instant(
        'COMMON.FIELDS.EVENT-TYPES.ELEARNING'
      ),
    },
  ];

  constructor(
    private myTrainingsService: MyTrainingsService,
    private loaderService: LoadingService,
    private translateService: TranslateService
  ) {
    this.loadMyTrainings();
  }

  ngOnInit(): void {}

  onTabChange(e: DxTabsTypes.ItemClickEvent) {
    this.filters.trainingTypes = [];
    this.filters.trainingCategories = [];
    this.filters.eventTypes = [];
    this.filters.searchString = '';

    this.selectedTab = e.itemData.id;
    this.filterMyTrainings();
  }

  loadMyTrainings() {
    this.loaderService
      .load(this.myTrainingsService.getMyTrainings())
      .subscribe((myTrainings) => {
        this.completedTrainingsCoursesIds =
          myTrainings.myCompletedTrainingsAndCoursesIds;
        this.trainingsCourses = [
          ...myTrainings.myTrainings.map((training) => ({
            ...training,
            type: 'Training',
          })),
          ...myTrainings.myCourses.map((course) => ({
            ...course,
            type: 'Course',
          })),
        ];

        this.tabs[0].text = `${this.tabs[0].text} (${
          this.trainingsCourses.length -
          this.completedTrainingsCoursesIds.length
        })`;
        this.tabs[1].text = `${this.tabs[1].text} (${this.completedTrainingsCoursesIds.length})`;

        this.filterMyTrainings();
      });
  }

  setTrainingCategories() {
    let trainingCategories: any[] = [];
    this.myTrainings.forEach((training: any) => {
      if (training.trainingCategories.length) {
        trainingCategories = [
          ...trainingCategories,
          ...training.trainingCategories,
        ];
      }
    });

    const seenIds = new Set();
    this.trainingCategories = trainingCategories.filter((category) => {
      if (seenIds.has(category.id)) {
        return false;
      } else {
        seenIds.add(category.id);
        return true;
      }
    });
  }

  filterByTrainingType() {
    this.myTrainings = this.myTrainings.filter((obj: any) => {
      if (
        !this.filters.trainingTypes.length ||
        this.filters.trainingTypes.includes(obj.trainingType)
      ) {
        return true;
      } else {
        return false;
      }
    });
  }

  filterByCategory() {
    if (this.filters.trainingCategories.length) {
      this.myTrainings = this.myTrainings.filter((training: any) =>
        training.trainingCategories.some((category: any) =>
          this.filters.trainingCategories!.includes(category.id)
        )
      );
    }
  }

  filterByEventType() {
    if (this.filters.eventTypes.length) {
      let filteredTrainings: any = [];
      if (this.filters.eventTypes.includes(0)) {
        filteredTrainings = [
          ...filteredTrainings,
          ...this.myTrainings.filter((obj: any) => {
            return obj.location && obj.location.hasOwnProperty('type') && obj.location.type == 0
              ? true
              : false;
          }),
        ];
      }
      if (this.filters.eventTypes.includes(1)) {
        filteredTrainings = [
          ...filteredTrainings,
          ...this.myTrainings.filter((obj: any) => {
            return obj.location && obj.location.type && obj.location.type != 0
              ? true
              : false;
          }),
        ];
      }
      if (this.filters.eventTypes.includes(2)) {
        filteredTrainings = [
          ...filteredTrainings,
          ...(this.myTrainings = this.myTrainings.filter((obj: any) => {
            return !obj.location && !obj.date ? true : false;
          })),
        ];
      }

      this.myTrainings = filteredTrainings;
    }
  }

  filterMyTrainings() {
    this.myTrainings = this.trainingsCourses.filter((obj: any) => {
      if (this.selectedTab == 0) {
        return !this.completedTrainingsCoursesIds.includes(obj.id);
      } else {
        return this.completedTrainingsCoursesIds.includes(obj.id);
      }
    });

    this.setTrainingCategories();
    this.filterByTrainingType();
    this.filterByCategory();
    this.filterByEventType();

    if (!!this.filters.searchString) {
      this.myTrainings = this.myTrainings.filter((obj: any) => {
        return (
          obj.name
            .toLowerCase()
            .includes(this.filters.searchString.toLowerCase()) ||
          (obj.description || '')
            .toLowerCase()
            .includes(this.filters.searchString.toLowerCase())
        );
      });
    }

    if (this.selectedTab == 0) {
      this.myTrainings = this.myTrainings.sort((a: any, b: any) => {
        const start = a.dates ? new Date(a.dates[0].start).getTime() : null;
        const end = b.dates ? new Date(b.dates[0].start).getTime() : null;

        if (start && end) {
          return start - end;
        } else {
          return 0;
        }
      });
    }
  }

  onSearchInput(event: any) {
    this.filters.searchString = event.value;
    this.filterMyTrainings();
  }

  onTrainingTypeSelected(e: any) {
    if (e.node.selected) {
      this.filters.trainingTypes = [...this.filters.trainingTypes, e.node.key];
    } else {
      this.filters.trainingTypes = this.filters.trainingTypes.filter(
        (id) => id !== e.node.key
      );
    }
    this.filterMyTrainings();
  }

  onTrainingCategorySelected(e: any) {
    if (e.node.selected) {
      this.filters.trainingCategories = [
        ...this.filters.trainingCategories!,
        e.node.key,
      ];
    } else {
      this.filters.trainingCategories = this.filters.trainingCategories!.filter(
        (id) => id !== e.node.key
      );
    }
    this.filterMyTrainings();
  }

  onEventTypeSelected(e: any) {
    if (e.node.selected) {
      this.filters.eventTypes = [...this.filters.eventTypes, e.node.key];
    } else {
      this.filters.eventTypes = this.filters.eventTypes.filter(
        (id) => id !== e.node.key
      );
    }
    this.filterMyTrainings();
  }
}

export interface MyTrainingsFilters {
  trainingTypes: number[];
  trainingCategories: string[];
  eventTypes: number[];
  searchString: string;
}
