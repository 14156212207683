import { Component, Input, Output, EventEmitter } from '@angular/core';
import { EventMemberModel } from '../../models/event-member.model';
import { TableColumn } from '../../models/table-column.model';
import { PersonSummaryModel } from '../../models/person/person-summary.model';
import { DxTabsTypes } from 'devextreme-angular/ui/tabs';
import { TrainingService } from 'src/app/services/training.service';
import { LoadingService } from 'src/app/services/loading.service';
import { PersonService } from 'src/app/services/person.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { PersonCreationAndUpdateModel } from 'src/app/models/person/person-creation-and-update.model';

@Component({
  selector: 'app-training-employee-popup',
  templateUrl: './training-employee-popup.component.html',
  styleUrls: ['./training-employee-popup.component.scss'],
})
export class TrainingEmployeePopupComponent {
  @Input()
  isPopupVisible!: boolean;

  @Input()
  tab: number = 0;

  @Input()
  acceptedMembers: EventMemberModel[] = [];

  @Input()
  openMembers: EventMemberModel[] = [];

  @Input()
  declinedMembers: EventMemberModel[] = [];

  @Input()
  requestedMembers: EventMemberModel[] = [];

  @Input()
  trainingId!: string;

  @Output() onPopupClose = new EventEmitter();
  @Output() onReloadMembers = new EventEmitter();

  persons: PersonSummaryModel[] = [];
  selectedTab: number = 0;

  tabs: any[] = [
    {
      id: 0,
      text: this.translateService.instant(
        'COMMON.FIELDS.EMPLOYEE-STATUSES.ACCEPTED'
      ),
    },
    {
      id: 1,
      text: this.translateService.instant(
        'COMMON.FIELDS.EMPLOYEE-STATUSES.OPEN'
      ),
    },
    {
      id: 2,
      text: this.translateService.instant(
        'COMMON.FIELDS.EMPLOYEE-STATUSES.DECLINED'
      ),
    },
  ];

  personsColumns: TableColumn[] = [
    {
      caption: '',
      dataField: 'this',
      cellType: 'action',
      disableFiltering: true,
      cellTemplate: 'actionsTemplate',
      actions: [
        {
          icon: 'check',
          class: 'green-icon',
          isShowAction: (person: PersonSummaryModel): boolean => {
            return !!this.requestedMembers.find(
              (member) => member.person.id === person.id
            );
          },
          onClick: (person: PersonSummaryModel): void => {
            this.loadingService
              .load(
                this.loadingService.load(
                  this.trainingService.approveMemberRequest(
                    this.trainingId,
                    person.id
                  )
                )
              )
              .subscribe(() => {
                this.onReloadMembers.emit();
              });
          },
        },
        {
          icon: 'close',
          class: 'red-icon',
          isShowAction: (person: PersonSummaryModel): boolean => {
            return !!this.requestedMembers.find(
              (member) => member.person.id === person.id
            );
          },
          onClick: (person: PersonSummaryModel): void => {
            this.loadingService
              .load(
                this.loadingService.load(
                  this.trainingService.declineMemberRequest(
                    this.trainingId,
                    person.id
                  )
                )
              )
              .subscribe(() => {
                this.onReloadMembers.emit();
              });
          },
        },
      ],
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.LAST-NAME'),
      dataField: 'lastName',
      allowEditing: true,
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.FIRST-NAME'),
      dataField: 'firstName',
      allowEditing: true,
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.EMAIL'),
      dataField: 'email',
      allowEditing: true,
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.EMPLOYEE-ID'),
      dataField: 'agentNumber',
      allowEditing: true,
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.LOCATION'),
      dataField: 'location.name',
    },
    {
      caption: this.translateService.instant('COMMON.FIELDS.ROLE'),
      dataField: 'role.name',
    },
  ];

  constructor(
    private loadingService: LoadingService,
    private trainingService: TrainingService,
    private translateService: TranslateService,
    private personService: PersonService,
    private toastrService: ToastrService
  ) {}

  onShow() {
    this.selectedTab = this.tab;
    this.tabs[0].text = `${this.translateService.instant(
      'COMMON.FIELDS.EMPLOYEE-STATUSES.ACCEPTED'
    )} (${this.acceptedMembers.length})`;
    this.tabs[1].text = `${this.translateService.instant(
      'COMMON.FIELDS.EMPLOYEE-STATUSES.OPEN'
    )} (${this.openMembers.length})`;
    this.tabs[2].text = `${this.translateService.instant(
      'COMMON.FIELDS.EMPLOYEE-STATUSES.DECLINED'
    )} (${this.declinedMembers.length})`;

    if (this.requestedMembers.length && this.tabs.length == 4) {
      this.tabs[3].text = `${this.translateService.instant(
        'COMMON.FIELDS.EMPLOYEE-STATUSES.REQUESTED'
      )} (${this.requestedMembers.length})`;
    } else if (this.requestedMembers.length && this.tabs.length == 3) {
      this.tabs.push({
        id: 3,
        text: `${this.translateService.instant(
          'COMMON.FIELDS.EMPLOYEE-STATUSES.REQUESTED'
        )} (${this.requestedMembers.length})`,
      });
    } else if (!this.requestedMembers.length && this.tabs.length == 4) {
      this.tabs.splice(3, 1);
    }

    this.setPersonsForTab();
  }

  onClose() {
    this.onPopupClose.emit();
  }

  onTabChange(e: DxTabsTypes.ItemClickEvent) {
    this.selectedTab = e.itemData.id;
    this.setPersonsForTab();
  }

  onRequestedMemberUpdate(event: any) {
    const personId = event.key;
    const updatedPerson: PersonCreationAndUpdateModel = {
      firstName: event.data.firstName,
      lastName: event.data.lastName,
      email: event.data.email,
      agentNumber: event.data.agentNumber,
      locationId: event.data.location ? event.data.location.id : null,
      roleId: event.data.role.id,
      trainingGroupIds: [],
      culture: event.data.culture,
      timeZone: event.data.timeZone,
    };

    this.loadingService
      .load(this.personService.updatePerson(personId, updatedPerson))
      .subscribe({
        next: () => {
          this.toastrService.success(
            this.translateService.instant('EMPLOYEE.FORM.EMPLOYEE-UPDATED')
          );
        },
        error: () => {
          this.translateService
            .get('COMMON.AN-ERROR-HAS-OCCURRED')
            .subscribe((value) => {
              this.toastrService.error(value);
            });
        },
      });
  }

  setPersonsForTab() {
    switch (this.selectedTab) {
      case 0:
        this.persons = this.acceptedMembers.map((member) => member.person);
        break;
      case 1:
        this.persons = this.openMembers.map((member) => member.person);
        break;
      case 2:
        this.persons = this.declinedMembers.map((member) => member.person);
        break;
      case 3:
        this.persons = this.requestedMembers.map((member) => member.person);
        break;
    }
  }

  public reloadPersonsCount () {
    this.tabs[0].text = `${this.translateService.instant(
      'COMMON.FIELDS.EMPLOYEE-STATUSES.ACCEPTED'
    )} (${this.acceptedMembers.length})`;
    this.tabs[1].text = `${this.translateService.instant(
      'COMMON.FIELDS.EMPLOYEE-STATUSES.OPEN'
    )} (${this.openMembers.length})`;
    this.tabs[2].text = `${this.translateService.instant(
      'COMMON.FIELDS.EMPLOYEE-STATUSES.DECLINED'
    )} (${this.declinedMembers.length})`;

    if (!this.requestedMembers.length && this.tabs.length == 4) {
      this.tabs.splice(-1);
    } else if (this.requestedMembers.length && this.tabs.length == 4) {
      this.tabs[3].text = `${this.translateService.instant(
        'COMMON.FIELDS.EMPLOYEE-STATUSES.REQUESTED'
      )} (${this.requestedMembers.length})`;
    }

    this.setPersonsForTab();
  }
}
